import React from 'react';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { FcGoogle } from 'react-icons/fc';

import { useSign } from '../../context/useSign';

const Login = () => {
  const { signup, signerror, isLoading } = useSign();

  const handleGoogle = () => {
    // handleSignIn();
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        // Handle successful sign-in
        const user = result.user;

        const newName = user.displayName;
        const namesArray = newName.split(' ');

        const firstName = namesArray[0];
        const email = user.email;
        const displayPic = user.photoURL;

        // console.log(firstName, email, displayPic);

        signup(firstName, email, displayPic);
      })
      .catch((error) => {
        // Handle sign-in error
        console.error('Error signing in:', error);
      });
    if (signerror) {
      alert(signerror);
    }
  };

  return isLoading === null ? (
    <button
      onClick={() => handleGoogle()}
      className="flex items-center gap-1 bg-google-button-blue rounded-full p-0.5 transition-colors duration-300 hover:bg-google-button-blue-hover hover:underline"
    >
      <span className="text-black tracking-wider">Signin with </span>
      <div className="flex items-center justify-center bg-white w-5 h-5 rounded-full">
        <FcGoogle />
      </div>
    </button>
  ) : (
    <>Loading...</>
  );
};

export default Login;
