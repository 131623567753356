import React from 'react';

import founders from '../../assets/founders.jpg';

const img1 = process.env.PUBLIC_URL + '/assets/srinivasrao.jpg';
const img2 = process.env.PUBLIC_URL + '/assets/nagamani.jpg';
const img3 = process.env.PUBLIC_URL + '/assets/naveen.jpg';

const crew = [
  { image: img1, name: 'Kanadam Srinivas Rao', position: 'Founder' },
  { image: img2, name: 'Kanadam Nagamani', position: 'Founder' },
  { image: img3, name: 'Kanadam Naveen & Naga Deepthi', position: 'Managers' },
];

const Founders = () => {
  return (
    <div className=" w-[100%] max-h-[2600px] mx-auto px-[20px] sm:px-[50px] lg:px-[100px] py-[20px] sm:pb-[30px] lg:pb-[40px]">
      <div className="flex flex-col gap-4  md:mx-auto ">
        <div className="flex flex-col text-center gap-2">
          <div className="max-w-[121px] mx-auto w-[100%] h-[3px] bg-[#650010] "></div>
          <h1 className="text-[24px] lg:text-[36px] font-googleNunito text-[#000] capitalize px-[15px]">
            Our Founders
          </h1>
          <p className="max-w-[1000px] mx-auto font-Montserrat text-[16px] lg:text-[18px] font-thin px-[15px]">
            Crafted with love, sprinkled with creativity, our founders bring the
            sweetest dreams to life in every delightful bite.
          </p>
        </div>

        <div className="flex flex-col lg:flex-row justify-center items-center gap-12">
          {crew.map((data, i) => {
            return (
              <div className="lg:w-[30%] w-[80%] bg-white shadow-2xl" key={i}>
                <div className="h-[20rem]">
                  <img
                    src={data.image}
                    alt=""
                    className="w-full h-full bg-contain"
                  />
                </div>
                <div className="text-center py-3 flex flex-col items-center justify-center gap-[5px]">
                  <p className="text-[17px] lg:text-[19px] text-[#650010]">
                    {data.name}
                  </p>
                  <p className="text-[13px] lg:text-[14px] text-gray-900">
                    {data.position}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Founders;
