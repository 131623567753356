import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import FetchImageUrl from '../../helpers/FetchImageUrl';
import HeroLoader from '../../components/Loader/Loader';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function HeroBanner({ Hero }) {
  const theme = useTheme();
  const { getImageUrl } = FetchImageUrl();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return Hero ? (
    <Box
      sx={{ flexGrow: 1, width: '100%' }}
      className="relative max-h-[100vh] overflow-hidden"
    >
      {/* <div
        className="absolute top-0 left-0 w-full h-full z-30 flex items-center justify-center"
        // style={{
        //   background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
        // }}
      >
        <h1 className="text-[24px] sm:text-[36px] lg:text-[48px] xl:text-[56px] text-white font-serif  font-bold">
          Sri Naveena Sweets
        </h1>
      </div> */}
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {Hero.filter((data) => data.bannerlocation === 'home-top').map(
          (step, index) => (
            <div key={index}>
              {Math.abs(activeStep - index) <= 1 ? (
                <Box
                  component="img"
                  sx={{
                    display: 'block',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                  src={getImageUrl(step.image)}
                  alt={step.label}
                />
              ) : null}
            </div>
          )
        )}
      </AutoPlaySwipeableViews>
    </Box>
  ) : (
    <HeroLoader />
  );
}

export default HeroBanner;
