import React, { useEffect, useState } from 'react';

import banner from '../../assets/sweetsbanner.jpg';
import banner2 from '../../assets/cakesbanner.jpg';
import banner3 from '../../assets/hotitemsbanner.jpg';
import banner4 from '../../assets/biscuitsbanner.jpg';

const Banner = ({ title }) => {
  const [imgtitle, setImgTitle] = useState('');

  useEffect(() => {
    title === 'sweets'
      ? setImgTitle(banner)
      : title === 'cakes'
      ? setImgTitle(banner2)
      : title === 'hot items'
      ? setImgTitle(banner3)
      : setImgTitle(banner4);
  }, [title]);

  return (
    <div
      className="hidden lg:flex w-full h-[200px] sm:h-[300px] lg:h-[400px] bg-no-repeat bg-center bg-cover relative z-30"
      style={{ backgroundImage: `url(${imgtitle})` }}
    >
      <div
        className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-[24px] sm:text-[36px] lg:text-[48px] font-serif text-white font-bold -z-30"
        style={{
          background:
            'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) ',
        }}
      >
        <h1 className="mt-[20px] capitalize">{title}</h1>
      </div>
    </div>
  );
};

export default Banner;
