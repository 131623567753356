import React, { useEffect } from 'react';

import Banner from '../components/Banner/Banner';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <Banner title={'Privacy Policy'} />
      <div className="p-6 lg:px-[8rem] font-googleterms">
        <div>
          <h2 className="font-bold text-xl mb-8">Privacy Policy</h2>
          <p>
            Thank you for choosing Sri Naveena Sweets and Bakery for your
            delightful experience. This Privacy Policy explains how we collect,
            use, and protect your personal information when you interact with
            our website.
          </p>
        </div>

        <h3 className="mt-4 font-semibold">Information We Collect</h3>

        <h6 className="mt-2">User Account Information:</h6>
        <p>
          When you choose to sign in using the "Sign in with Google" button
          through Firebase authentication, we collect and securely store
          essential user details such as your name, email address, and unique
          user identifier provided by Google.
        </p>

        <h6>Order and Address Details:</h6>
        <p>
          Your order details, address information, favorites, and cart details
          are stored as secure documents in MongoDB Atlas. This information is
          kept confidential and is not exposed to any third party.
        </p>

        <h6>OTP Verification:</h6>
        <p>
          For secure order confirmation, we utilize Firebase for OTP
          verification. Your contact number is collected solely for this
          purpose.
        </p>

        <h6>JWT Token:</h6>
        <p>
          A secure JWT (JSON Web Token) is generated during each user login and
          is stored in your browser. This token serves as a secure identifier
          for all subsequent API calls, ensuring the integrity and privacy of
          your data.
        </p>

        <h3 className="mt-4 font-semibold">How We Use Your Information</h3>
        <p>Your information is utilized for the following purposes:</p>

        <ol className="flex flex-col">
          <li className="flex lg:flex-row flex-col">
            <p className="font-semibold"> User Authentication:</p> To verify
            your identity and provide secure access to your account.
          </li>
          <li className="flex lg:flex-row flex-col">
            <p className="font-semibold">Order Processing:</p> To process and
            fulfill your orders, including order confirmation through OTP
            verification.
          </li>
          <li className="flex lg:flex-row flex-col">
            <p className="font-semibold">Personalization:</p> To enhance your
            experience on our website by customizing content and providing
            relevant information.
          </li>
        </ol>

        <h3 className="mt-4 font-semibold">Data Security</h3>
        <p>
          We prioritize the security of your data and have implemented various
          measures to safeguard it:
        </p>

        <ol className="flex flex-col">
          <li>
            <p>
              <span className="font-semibold">Encryption:</span> All data
              transmitted between your browser and our servers is encrypted to
              protect against unauthorized access.
            </p>
          </li>
          <li>
            <p>
              <span className="font-semibold">
                Secure Socket Layer (SSL) Technology:
              </span>{' '}
              Our website employs SSL technology to secure data transfer during
              your interactions with our platform.
            </p>
          </li>
          <li>
            <p>
              <span className="font-semibold">Regular Security Audits:</span> We
              conduct regular security audits to identify and address potential
              vulnerabilities.
            </p>
          </li>
        </ol>

        <h3 className="mt-4 font-semibold">Third-Party Services</h3>
        <p>
          We use Google Firebase for authentication services. You can refer to
          Google Firebase's privacy policy to understand how they handle and
          secure your data.
        </p>

        <h3 className="mt-4 font-semibold">Your Choices</h3>
        <p>You have the following rights:</p>

        <ol>
          <li>
            <p>Access and Update Your Information:</p> You can access and update
            your account information through the website.
          </li>
          <li>
            <p>Data Deletion:</p> You have the right to request the deletion of
            your account and associated data.
          </li>
        </ol>

        <h3 className="mt-4 font-semibold">Changes to This Privacy Policy</h3>
        <p>
          We may update our Privacy Policy periodically. Any changes will be
          effective immediately upon posting the updated policy on our website.
        </p>

        <p className="text-center lg:px-12 text-gray-700 mt-12">
          Thank you for trusting Sri Naveena Sweets and Bakery for your sweet
          cravings!
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
