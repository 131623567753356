import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';

import { RiDeleteBin6Line } from 'react-icons/ri';

const AddressCard = ({ item, i, handleDelete, handleEdit }) => {
  return (
    <Paper elevation={3}>
      <Card sx={{ position: 'relative' }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {item.fullname}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Mobile: {item.mobile}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Email: {item.email}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Full Address: {item.fullAddress}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Landmark: {item.landmark}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            State: {item.state}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Pincode: {item.pincode}
          </Typography>
        </CardContent>
        <CardContent
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 10,
            display: 'flex',
            gap: '12px',
            padding: window.innerWidth < 768 && 0,
          }}
        >
          <ButtonGroup size="small" sx={{ display: 'flex', gap: '12px' }}>
            <Button onClick={() => handleEdit(i)}>Edit</Button>
          </ButtonGroup>

          <div className="w-full text-right text-[color:var(--secondary-bg)]">
            <button
              className="inline-flex items-center gap-2 focus:outline-none mr-4"
              onClick={() => handleDelete(item.aId)}
            >
              <RiDeleteBin6Line />
              Delete
            </button>
          </div>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default AddressCard;
