import * as React from 'react';
import Box from '@mui/material/Box';

import { UseAuthContext } from '../../context/useAuthContext';

import FetchMongo from '../../helpers/FetchMongo';
import PostMongo from '../../helpers/PostMongo';

import AddressCard from '../Address/AddressCard';
import AddressModal from '../Address/Modal';

export default function UserAddress() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [EditData, setEditdata] = React.useState();

  const { user } = UseAuthContext();
  const { fetchUserData, userData } = FetchMongo();
  const { deleteAddress } = PostMongo();
  const [addresses, setAddresses] = React.useState();

  React.useEffect(() => {
    if (user) {
      fetchUserData();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (userData) {
      setAddresses(userData.addresses);
    }
  }, [userData]);

  const handleNew = () => {
    setEditdata(null);
    handleOpen();
  };

  const handleEdit = (index) => {
    setEditdata(addresses[index]);
    handleOpen();
  };

  const handleDelete = async (aId) => {
    await deleteAddress(aId).then(() => fetchUserData());
  };

  return (
    <div className="lg:pl-4 pt-4 flex flex-col">
      <AddressModal
        handleClose={() => setOpen(false)}
        handleNew={handleNew}
        EditData={EditData}
        open={open}
        fetchAgain={fetchUserData}
      />
      <div className="lg:p-4">
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 1,
              width: '100%',
            },
          }}
        >
          {addresses ? (
            addresses.map((item, i) => {
              return (
                <AddressCard
                  key={i}
                  item={item}
                  i={i}
                  handleEdit={handleEdit}
                  fetchUserData={fetchUserData}
                  handleDelete={handleDelete}
                />
              );
            })
          ) : (
            <></>
          )}
        </Box>
      </div>
    </div>
  );
}
