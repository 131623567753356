import { useState } from 'react';

import Backdrop from '@mui/material/Backdrop';
import { Box, Fade, Modal } from '@mui/material';

import { MdOutlineDone } from 'react-icons/md';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
};

const OrderPlaced = () => {
  const [open, setOpen] = useState(true);

  const closePop = () => {
    setOpen(false);

    window.location.reload();
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={closePop}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="group before:hover:scale-95 before:hover:h-72 before:hover:w-80 before:hover:rounded-b-2xl before:transition-all before:duration-500 before:content-[''] before:w-80 before:h-24 before:rounded-t-2xl before:bg-gradient-to-bl from-sky-200 via-orange-200 to-green-700 before:absolute before:top-0 w-80 h-72 relative bg-slate-50 flex flex-col items-center justify-center gap-2 text-center rounded-2xl overflow-hidden">
            <MdOutlineDone className="w-32 h-32 text-white bg-green-700 mt-8 rounded-[50%] border-4 border-slate-50 z-10 group-hover:scale-150 group-hover:-translate-x-24  group-hover:-translate-y-20 transition-all duration-500" />

            <div className="z-10  group-hover:-translate-y-10 transition-all duration-500">
              <span className="text-2xl font-semibold">Order placed</span>
              <p>Delivered </p>
              <p>Futher order details will be communicated through whatsapp</p>
            </div>
            <p
              className="bg-blue-700 px-4 py-1 mb-4 text-slate-50 rounded-md z-10 cursor-pointer hover:scale-125 transition-all duration-500 hover:bg-blue-500"
              onClick={closePop}
            >
              Continue shopping
            </p>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default OrderPlaced;
