import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import FetchMongo from '../helpers/FetchMongo';
import { FetchSanity } from '../helpers/FetchSanity';
import FetchImageUrl from '../helpers/FetchImageUrl';
import PostMongo from '../helpers/PostMongo';

import Banner from '../components/Banner/Banner';

const Favorites = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const { fetchFavData, favData } = FetchMongo();
  const { fetchItems, Items } = FetchSanity();
  const { getImageUrl } = FetchImageUrl();
  const { deleteFavItem } = PostMongo();

  const fav = process.env.PUBLIC_URL + '/assets/fav.jpg';

  useEffect(() => {
    fetchFavData();
    fetchItems();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const mergedArray =
    favData &&
    favData
      .filter(
        (cartItem) =>
          Items &&
          Items.some((product) => product.item_id === cartItem.productId)
      )
      .map((cartItem) => {
        const matchingProduct = Items.find(
          (product) => product.item_id === cartItem.productId
        );
        return {
          productId: cartItem.productId,
          item_id: matchingProduct.item_id,
          name: matchingProduct.name,
          description: matchingProduct.decription,
          images: matchingProduct.images,
          price: matchingProduct.sale_price,
          weight: matchingProduct.weight,
          category: matchingProduct.items_category,
        };
      });

  return favData && favData.length > 0 ? (
    <>
      <Banner title={'Favorites'} />
      <div className="p-4 lg:px-12">
        <div className="pl-4 lg:px-10 2xl:px-20 flex flex-row justify-start items-end space-x-4">
          <h1 className="text-4xl font-semibold leading-9 text-gray-800">
            Favourites
          </h1>
          <p className="text-base leading-4 text-gray-600 pb-1">
            ({favData.length} Items)
          </p>
        </div>
        <div className="hidden sm:flex flex-col px-[12rem] justify-center items-start">
          <table className="w-full mt-16 whitespace-nowrap">
            <thead
              aria-label="table heading"
              className="w-full h-16 text-left py-6 bg-gray-50 border-gray-200 border-b "
            >
              <tr>
                <th className="text-base font-medium leading-4 text-gray-600 2xl:pl-20 pl-4 lg:pl-10">
                  YOUR PRODUCT
                </th>
                <th className="text-base font-medium leading-4 text-gray-600 pl-6 lg:pl-20 2xl:pl-52">
                  DESCRIPTION
                </th>
                <th className="text-base font-medium leading-4 text-gray-600 pl-6 lg:pl-20 2xl:pl-52">
                  PRICE
                </th>
                <th className="text-base font-medium leading-4 text-gray-600 2xl:pl-28 2xl:pr-20 pr-4 lg:pr-10" />
              </tr>
            </thead>
            <tbody className="w-full text-left">
              {mergedArray.map((item, i) => {
                return (
                  <tr className="border-gray-200 border-b" key={i}>
                    <th className="flex justify-center">
                      <Link to={item.item_id}>
                        <img
                          className="pl-4 lg:p-4 2xl:pl-20 w-[10rem]"
                          src={getImageUrl(item.images[0])}
                          alt={item.name}
                        />
                      </Link>
                    </th>
                    <th className="mt-10 text-base font-medium leading-4 text-gray-600 pl-6 lg:pl-20 2xl:pl-52">
                      <p className="text-base leading-4 text-gray-800">
                        {item.name}
                      </p>
                      <p className="mt-4 text-gray-700 text-xs">
                        {item.description}
                      </p>
                    </th>
                    <th className="my-10  pl-6 lg:pl-20 2xl:pl-52">
                      <p>₹ {item.price}.00</p>
                    </th>
                    <th className="my-10 pl-4 lg:pl-12  2xl:pl-28 pr-4 2xl:pr-20">
                      <button
                        onClick={() =>
                          deleteFavItem(item.item_id).then(() => fetchFavData())
                        }
                        className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800 text-base leading-none text-red-600 hover:text-red-800"
                      >
                        <p>Remove Item</p>
                      </button>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className=" flex justify-center items-center">
          <div className="sm:hidden grid grid-cols-2 justify-center items-start ">
            {mergedArray.map((item, i) => {
              return (
                <div
                  className="border-gray-200 border w-full m-2 shadow"
                  key={i}
                >
                  <div className=" flex flex-col jusitfy-center items-start mt-10 h-[12rem]">
                    <img
                      src={getImageUrl(item.images[0])}
                      alt={item.name}
                      className="w-full h-full"
                    />
                  </div>
                  <div className="mt-6 justify-between border-t p-2 w-full jusitfy-center items-center">
                    <p className="w-36 text-base leading-6 text-gray-800">
                      {item.name}
                    </p>

                    <p className="text-base font-semibold leading-4 text-gray-800">
                      {item.price}.00
                    </p>

                    <button
                      className="mt-4 leading-none text-red-600 hover:text-red-800"
                      onClick={() =>
                        deleteFavItem(item.item_id).then(() => fetchFavData())
                      }
                    >
                      Remove Item
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <Banner title={'Favorites'} />
      <div className="lg:min-h-screnn flex justify-center items-center lg:p-12 my-12">
        <div className="w-1/2 flex flex-col items-center">
          <h4 className="lg:text-[4rem] text-[2rem] text-nowrap font-googleBiscuits">
            No items in wishlist
          </h4>
          <img src={fav} alt="" className="w-full h-full" />
        </div>
      </div>
    </>
  );
};

export default Favorites;
