import * as React from 'react';
import Box from '@mui/material/Box';

import { UseAuthContext } from '../../context/useAuthContext';

import AddressCard from '../Address/AddressCard';
import AddressModal from '../Address/Modal';

import FetchMongo from '../../helpers/FetchMongo';
import PostMongo from '../../helpers/PostMongo';

const Address = ({ setId }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [EditData, setEditdata] = React.useState();

  const { user } = UseAuthContext();
  const { fetchUserData, userData } = FetchMongo();
  const { deleteAddress } = PostMongo();
  const [addresses, setAddresses] = React.useState();

  React.useEffect(() => {
    if (user) {
      fetchUserData();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (userData) {
      setAddresses(userData.addresses);
    }
  }, [userData]);

  const handleNew = () => {
    setEditdata(null);
    handleOpen();
  };

  const handleEdit = (index) => {
    setEditdata(addresses[index]);
    handleOpen();
  };

  const handleDelete = async (aId) => {
    await deleteAddress(aId).then(() => fetchUserData());
  };

  const [index, setSelectedIndex] = React.useState(0);

  React.useEffect(() => {
    if (addresses && addresses.length > 0) {
      setId(addresses[0].aId);
    }
  }, [addresses]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRadioChange = ({ addressId, index }) => {
    setId(addressId);
    setSelectedIndex(index);
  };

  // console.log(addresses);
  return (
    <div className="lg:pl-4 pt-4 flex flex-col">
      <AddressModal
        handleClose={handleClose}
        handleNew={handleNew}
        EditData={EditData}
        open={open}
        fetchAgain={fetchUserData}
      />
      <div className="lg:p-4">
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 1,
              width: '100%',
            },
          }}
        >
          {addresses ? (
            addresses.map((item, i) => {
              return (
                <div key={item.aId} className="relative">
                  <AddressCard
                    item={item}
                    i={i}
                    handleEdit={handleEdit}
                    fetchUserData={fetchUserData}
                    handleDelete={handleDelete}
                  />
                  <input
                    type="radio"
                    name="cardRadio"
                    className="absolute top-6 right-6 size-6 text-blue-700"
                    onChange={() =>
                      handleRadioChange({ addressId: item.aId, index: i })
                    }
                    checked={i === index}
                  />
                </div>
              );
            })
          ) : (
            <div className="relative flex w-64 animate-pulse gap-2 p-4">
              <div className="h-12 w-12 rounded-full bg-slate-400"></div>
              <div className="flex-1">
                <div className="mb-1 h-5 w-3/5 rounded-lg bg-slate-400 text-lg"></div>
                <div className="h-5 w-[90%] rounded-lg bg-slate-400 text-sm"></div>
              </div>
              <div className="absolute bottom-5 right-0 h-4 w-4 rounded-full bg-slate-400"></div>
            </div>
          )}
        </Box>
      </div>
    </div>
  );
};

export default Address;
