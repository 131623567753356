import React from 'react';

import fastdelivery from '../../assets/fastdelivery.jpg';
import payments from '../../assets/payments.jpg';
import sweets from '../../assets/sweets.jpg';
import occasions from '../../assets/occasions.jpg';

const Services = () => {
  return (
    <div className="w-full max-h-[10000px] ">
      <div className="px-[20px] sm:px-[35px] lg:px-[60px] py-[20px]">
        <div className="flex flex-col md:flex-row gap-[20px] w-full h-full justify-between relative">
          <div className="bg-[#F603021A] w-full h-full rounded-lg">
            <div className="py-[20px] sm:py-[30px] lg:py-[40px] w-full h-full flex flex-col text-center justify-center items-center px-[25px] sm:px-[45px] lg:px-[70px] gap-[20px]">
              <div className="flex flex-col gap-[10px]">
                <h1 className="text-[24px] sm:text-[28px] lg:text-[32px] font-bold font-serif">
                  Our Services
                </h1>
                <h1 className="text-[20px] sm:text-[24px] lg:text-[28px] font-bold font-serif">
                  Always Near You
                </h1>
              </div>
              <div>
                <p>
                  Indulge in the exquisite world of flavors with our delectable
                  sweets and cakes, delivered right to your doorstep in just 3
                  hours. At Sri Naveena Sweets, we prioritize your satisfaction
                  and convenience, offering a range of services to make your
                  experience delightful.
                </p>
              </div>
              <div className="flex flex-col md:flex-row items-center justify-center px-[10px] gap-[18px]">
                <div className="flex flex-col gap-[5px] items-center justify-center">
                  <img
                    src={fastdelivery}
                    alt=""
                    className="h-[8rem] mix-blend-multiply"
                  />
                  <p className="font-bold font-googleNunito">Fast Delivery</p>
                  <p>
                    Fast-track your cravings with our swift 3-hour delivery
                    service.
                  </p>
                </div>
                <div className="flex flex-col gap-[5px] items-center justify-center">
                  <img
                    src={payments}
                    alt=""
                    className="h-[8rem] mix-blend-multiply"
                  />
                  <p className="font-bold font-googleNunito">Secure Payments</p>
                  <p>
                    We prioritize the security of your transactions, ensuring a
                    seamless and trustworthy payment experience.
                  </p>
                </div>
                <div className="flex flex-col gap-[5px] items-center justify-center">
                  <img
                    src={sweets}
                    alt=""
                    className="h-[8rem] mix-blend-multiply"
                  />
                  <p className="font-bold font-googleNunito">
                    Delicious Sweets
                  </p>
                  <p>
                    Savor the richness of our handcrafted sweets and cakes, made
                    with love and the finest ingredients.
                  </p>
                </div>
                <div className="flex flex-col gap-[5px] items-center justify-center">
                  <img
                    src={occasions}
                    alt=""
                    className="h-[8rem] mix-blend-multiply"
                  />
                  <p className="font-bold font-googleNunito">
                    Occasional Items
                  </p>
                  <p>
                    Celebrate special moments with our exclusive range of
                    occasional items, adding a touch of sweetness to your
                    festivities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
