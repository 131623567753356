import { useState } from 'react';
import { UseAuthContext } from './useAuthContext';
import { useSnackbar } from 'notistack';

export const useSign = () => {
  const [signerror, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = UseAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const BACKEND_URL =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000'
      : 'https://srinaveenasweets.onrender.com';

  const signup = async (name, email, displayPic) => {
    setIsLoading(true);
    setError(null);

    const response = await fetch(`${BACKEND_URL}/api/user/checkAuth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        name,
        email,
        displayPic,
      }),
    });

    const json = await response.json();

    if (!response.ok) {
      setIsLoading(false);
    }
    if (response.ok) {
      enqueueSnackbar('LoggedIn succesfully!', {
        variant: 'success',
        autoHideDuration: 2000,
      });

      localStorage.setItem('user', JSON.stringify(json));

      // update the auth context
      dispatch({ type: 'LOGIN', payload: json });

      setIsLoading(false);
    }
  };
  return { signup, isLoading, signerror };
};
