import { UseAuthContext } from '../context/useAuthContext';
import { UseOrderContext } from '../context/useOrderContext';
import { useNavigate } from 'react-router-dom';

const Orders = () => {
  const { user } = UseAuthContext();
  const logo_path = process.env.PUBLIC_URL + '/assets/logo.png';

  const BACKEND_URL =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000'
      : 'https://srinaveenasweets.onrender.com';

  const handlePayment = async ({ amount, currency, receipt, notes, items }) => {
    const response = await fetch(`${BACKEND_URL}/api/order/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        amount,
        currency,
        receipt,
        notes,
      }),
    });

    const order = await response.json();

    var options = {
      key: 'rzp_live_Cgjb78AWlZXFCU',
      amount,
      currency,
      name: 'Sri Naveena Sweets and Bakery',
      description: 'Test Transaction',
      image: logo_path,
      order_id: order.id,
      handler: async function (response) {
        const body = {
          ...response,
        };

        const validateRes = await fetch(`${BACKEND_URL}/api/order/verify`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({ body, order, items }),
        });

        // const jsonRes = await validateRes.json();
        if (validateRes.ok) {
          render({ amount, receipt, notes, items });
        }
      },
      callback_url: 'https://eneqd3r9zrjok.x.pipedream.net/',
      prefill: {
        name: notes.name,
        email: notes.email,
        contact: notes.contact,
      },
      notes: {
        address: notes.address,
      },
      theme: {
        color: '#ebe3c4',
      },
    };

    var rzp1 = new window.Razorpay(options);

    rzp1.on('payment.failed', function (response) {
      alert(response.error.code + ': ' + response.error.description);
    });

    rzp1.open();
  };

  const navigate = useNavigate();

  const { orderdispatch } = UseOrderContext();

  function render({ amount, receipt, notes, items }) {
    orderdispatch({
      type: 'SET_ORDER',
      payload: { amount: amount / 100, receipt, notes, items },
    });

    navigate('/');
  }

  return { handlePayment };
};

export default Orders;
