import React from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';
import { Breadcrumbs } from '@mui/material';

const BreadCrumbs = ({ productInfo, category }) => {
  return (
    <Breadcrumbs
      sx={{
        borderRadius: '4px',
        maxWidth: '400px',
        minWidth: '320px',
        width: '100%',
        backgroundColor: 'rgb(255,255,255)',
        display: 'flex',
        lineHeight: 'normal',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '5px',
      }}
      separator="/"
      name="breadcrumb"
      separatorcolor="#101113"
    >
      <Link
        sx={{
          textDecoration: 'none',
          font: '600 15px sans-serif',
          color: 'rgb(59, 67, 76)',
          letterSpacing: '0.5px',
        }}
        variant="subtitle"
        listType="ul"
        to="/"
        target="_self"
      >
        Home
      </Link>

      <Link
        sx={{
          textDecoration: 'none',
          font: '600 15px sans-serif',
          color: 'rgb(59, 67, 76)',
          letterSpacing: '0.5px',
        }}
        variant="subtitle"
        listType="ul"
        to={`/${productInfo.items_category}`}
        target="_self"
      >
        {category}
      </Link>

      <Typography
        sx={{
          fontWeight: '600',
          color: 'rgb(59, 67, 76)',
          letterSpacing: '0.5px',
          opacity: '0.8',
          fontSize: '17px',
        }}
        variant="h4"
      >
        {productInfo.name}
      </Typography>
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
