import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import firebase from 'firebase/compat/app';

import { AiOutlineInfoCircle } from 'react-icons/ai';

import { Box, Button } from '@mui/material';
import { Typography } from '@material-ui/core';

import OTP from './SendOTP';
import Sending from '../Loader/Sending';

const ConfirmOrder = ({ mobile, placeOrder }) => {
  const [checked, setChecked] = useState(false);
  const [openOTP, setOpen] = useState(false);

  const [loader, setloader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [otp, setOtp] = React.useState('');

  const phoneNumber = '+919618335976';

  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: (response) => {
          // console.log('ReCaptcha Cerified');
        },
      }
    );
  };

  // const onSignInSubmit = async () => {
  //   enqueueSnackbar('Working on Payments, we are taking COD orders!', {
  //     variant: 'warning',
  //     autoHideDuration: 5000,
  //   });
  // };

  const onSignInSubmit = async () => {
    configureCaptcha();
    setloader(true);

    const phoneNumber = '+91' + mobile;

    const appVerifier = window.recaptchaVerifier;

    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        setloader(false);
        setOpen(true);

        window.confirmationResult = confirmationResult;

        enqueueSnackbar(`OTP sent to ${mobile}!`, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      })
      .catch((error) => {
        setOpen(false);
        enqueueSnackbar(`Failed to send otp to ${mobile}!`, {
          variant: 'danger',
          autoHideDuration: 2000,
        });
      });
  };

  const handlePayment = async () => {
    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        enqueueSnackbar(`OTP verified!`, {
          variant: 'success',
          autoHideDuration: 2000,
        });
        placeOrder();
      })
      .catch((error) => {
        // console.log(error);
        enqueueSnackbar(`invalid otp!`, {
          variant: 'danger',
          autoHideDuration: 2000,
        });
      });
  };
  return (
    <>
      <p className="flex gap-2 item-center">
        <AiOutlineInfoCircle />
        Please note that orders, once placed, are non-cancellable.
      </p>
      <label className="flex items-center scale-100 gap-1 my-2">
        <input
          type="checkbox"
          onChange={() => setChecked(!checked)}
          className='h-[1.4rem] w-[1.4rem] inline-block appearance-none relative bg-[#F2ECFF] cursor-pointer overflow-hidden rounded-[15%] after:content-[""] after:block after:h-[0.9rem] after:w-[0.4rem] after:opacity-0 after:rotate-45 after:-translate-x-2/4 after:-translate-y-2/4 after:absolute after:transition-[0.25s] after:duration-[ease] after:border-r-[0.31rem] after:border-r-[#a0ffe7] after:border-b-[0.31rem] after:border-b-[#a0ffe7] after:border-solid after:left-[50%] after:top-[50%] before:content-[""] before:block before:h-0 before:w-0 before:bg-[#00C896] before:opacity-50 before:-translate-x-2/4 before:-translate-y-2/4 before:absolute before:transition-[0.3s] before:duration-[ease] before:rounded-[50%] before:left-2/4 before:top-2/4 checked:before:h-[130%] checked:before:w-[130%] checked:before:opacity-[100%] checked:after:opacity-[100%]'
        />
        <span> Agree to terms & conditions to confirm order</span>
      </label>
      <div id="recaptcha-container" />
      {checked &&
        (!openOTP && !loader ? (
          <div className="flex flex-col items-center gap-4 justify-between mt-4">
            <Button
              onClick={onSignInSubmit}
              variant="contained"
              color="primary"
              sx={{ width: '100%' }}
            >
              Verify {mobile} & pay
            </Button>

            <Button
              variant="contained"
              color="primary"
              sx={{ width: '100%' }}
              onClick={() => (window.location.href = `tel:${phoneNumber}`)}
            >
              For COD call us at +91-9618335976
            </Button>
          </div>
        ) : (
          !openOTP && <Sending />
        ))}

      {openOTP && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Typography variant="p" color="textSecondary">
            Did not receive the code? Retry in a few minutes.
          </Typography>
          <OTP value={otp} onChange={setOtp} length={6} />

          <Button onClick={handlePayment}>Proceed to pay</Button>
        </Box>
      )}
    </>
  );
};

export default ConfirmOrder;
