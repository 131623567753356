import React from 'react';

const ShippingPolicy = () => {
  return (
    <div className="p-6 lg:px-[8rem] font-googleterms">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <h1 className="font-bold text-xl mb-8">Shipping Policy</h1>

          <p>Last updated: April 5, 2024</p>

          <p>
            Thank you for choosing Sri Naveena Sweets for your sweet cravings!
            We're committed to ensuring a seamless delivery experience for our
            customers. Please read our shipping policy carefully:
          </p>
        </div>

        <ol className="flex flex-col gap-4 my-8">
          <li>
            <h4 className="font-semibold">Delivery Timeframe:</h4>
            <p>
              • Upon order confirmation, your delivery will be processed within
              3 hours to a maximum of 12 hours.
            </p>
            <p>
              • Please note that delivery times may vary depending on factors
              such as location and order volume.
            </p>
          </li>
          <li>
            <h4 className="font-semibold">Order Confirmation:</h4>
            <p>
              • Once your order is confirmed, it will be reflected in your
              profile page on our website.
            </p>
          </li>
          <li>
            <h4 className="font-semibold">Delivery Areas:</h4>
            <p>
              • We currently offer delivery services exclusively within and
              around Guntur, Andhra Pradesh.
            </p>
          </li>
          <li>
            <h4 className="font-semibold">Delivery Charges:</h4>
            <p>
              • We're pleased to offer free delivery to all locations within our
              delivery area.
            </p>
          </li>
          <li>
            <h4 className="font-semibold">Delivery Process:</h4>
            <p>
              • Our dedicated delivery team will ensure your order is promptly
              dispatched and delivered to your specified location.
            </p>
          </li>
          <li>
            <h4 className="font-semibold">Recipient Availability:</h4>
            <p>
              • Please ensure someone is available to receive the order at the
              specified delivery address during the designated timeframe.
            </p>
          </li>
          <li>
            <h4 className="font-semibold">Delivery Disclaimer:</h4>
            <p>
              • While we strive to meet the specified delivery timeframe, please
              note that unexpected circumstances such as extreme weather
              conditions may affect delivery times. Your patience and
              understanding are appreciated.
            </p>
          </li>
        </ol>
      </div>

      <p className="text-center">
        At Sri Naveena Sweets, we're dedicated to providing you with the best
        possible service. We hope you enjoy our delicious sweets and look
        forward to serving you again soon!
      </p>

      <p className="text-center my-4 text-[12px]">
        Note: This shipping policy is subject to change without prior notice.
        Please refer to the latest version available on our website for the most
        up-to-date information.
      </p>
    </div>
  );
};

export default ShippingPolicy;
