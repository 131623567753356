import React from 'react';

import FetchImageUrl from '../../helpers/FetchImageUrl';

import { Link } from 'react-router-dom';

import { FaShoppingCart } from 'react-icons/fa';

const Biscuits = ({ Biscuits }) => {
  const { getImageUrl } = FetchImageUrl();
  return (
    <>
      <div>
        <h3
          className={`cakesHead font-googleBiscuits  ${
            window.innerWidth > 768 ? 'text-[6rem]' : 'text-[4em]'
          } text-center my-5 text-[color:var(--buttons-bg)] bg-cover bg-center w-full tracking-[3px]`}
        >
          Biscuits
        </h3>

        <div
          className={`noscrollbar flex max-w-inherit overflow-x-scroll px-[20px] sm:px-[35px] lg:px-[60px] py-[20px] `}
        >
          {Biscuits &&
            Biscuits.map((item, i) => {
              return (
                <Link
                  key={i}
                  to={`biscuits/${item.item_id}`}
                  className="mx-3 rounded-[50%] border border-[#F60302] "
                >
                  <div
                    className={`${
                      window.innerWidth > 768
                        ? 'w-[18em] h-[18em]'
                        : 'w-[12em] h-[12em]'
                    } hover:shadow-md  hover:shadow-black rounded-[50%] relative overflow-hidden group transition-all ease-in-out duration-300 bg-no-repeat bg-center bg-cover flex flex-col bg-black items-center justify-center mx-auto  sm:min-w-full min-h-[250px] lg:min-h-[290px] xl:min-h-[325px] `}
                    style={{
                      backgroundImage: `url(${getImageUrl(item.images[0])})`,
                    }}
                  >
                    <div
                      className="absolute top-96 left-0 w-full h-full group-hover:top-0 rounded-[50%] flex items-center justify-center"
                      style={{
                        background:
                          'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
                      }}
                    >
                      <h1 className="pt-[150px] text-white cursor-pointer">
                        <FaShoppingCart size={45} />
                      </h1>
                    </div>
                    <h1 className=" group-hover:text-white text-[24px] sm:text-[28px] lg:text-[32px] font-serif font-bold z-10 rounded-[50%] group-hover:animate-bounce">
                      {item.name}
                    </h1>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Biscuits;
