import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import FetchImageUrl from '../../helpers/FetchImageUrl';

function srcset(image, size) {
  return {
    src: `${image}?w=${size * 1}&h=${size * 1}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * 1}&h=${
      size * 1
    }&fit=crop&auto=format&dpr=2 1x`,
  };
}

export default function Gallery({ itemData }) {
  itemData = itemData && itemData.slice(0, window.innerWidth > 768 ? 12 : 9);

  const { getImageUrl } = FetchImageUrl();
  return (
    <div>
      <h3
        className={`cakesHead font-googleGallery  
        ${window.innerWidth > 768 ? 'text-[4rem]' : 'text-[3rem]'} 
        text-center my-5 bg-cover bg-center w-full tracking-[4px]`}
      >
        Gallery
      </h3>
      <div className="flex justify-center">
        {itemData && itemData.length > 0 && (
          <ImageList
            sx={{
              width: '100%',
            }}
            variant="quilted"
            cols={window.innerWidth > 768 ? 4 : 3}
          >
            {itemData.map((item, i) => (
              <ImageListItem key={i}>
                <img
                  {...srcset(getImageUrl(item.image), 121)}
                  alt={item.name}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </div>
    </div>
  );
}
