import { createClient } from '@sanity/client';

import imageUrlBuilder from '@sanity/image-url';

const client = createClient({
  projectId: '3avvokt5',
  dataset: 'sanity',
  useCdn: true,
  apiVersion: '2024-01-15',
});

const builder = imageUrlBuilder(client);

const FetchImageUrl = () => {
  const getImageUrl = (image) => {
    return builder.image(image).url();
  };

  return {
    getImageUrl,
  };
};

export default FetchImageUrl;
