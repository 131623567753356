import React, { useEffect } from 'react';

import FetchMongo from '../../helpers/FetchMongo';
import { FetchSanity } from '../../helpers/FetchSanity';
import FetchImageUrl from '../../helpers/FetchImageUrl';

const UserOrders = () => {
  const { fetchOrders, orders } = FetchMongo();
  const { fetchItems, Items } = FetchSanity();

  const { getImageUrl } = FetchImageUrl();

  useEffect(() => {
    fetchOrders();
    fetchItems();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ItemsArray =
    orders &&
    orders.flatMap((element) =>
      element.items.map((item) => ({
        receipt: element.receipt,
        item,
        notes: element.notes,
        date: element.date,
        time: element.time,
        paid: element.amount,
        status: element.status,
      }))
    );

  const mergedArray =
    ItemsArray &&
    ItemsArray.filter(
      (orderItem) =>
        Items &&
        Items.some((product) => product.item_id === orderItem.item.itemId)
    ).map((orderItem) => {
      const matchingProduct = Items.find(
        (product) => product.item_id === orderItem.item.itemId
      );
      return {
        orderId: orderItem.receipt,
        quantity: orderItem.item.quantity,
        itemId: matchingProduct.item_id,
        ItemName: matchingProduct.name,
        description: matchingProduct.decription,
        image: matchingProduct.images[0],
        price: matchingProduct.sale_price.toFixed(1),
        weight: matchingProduct.weight,
        category: matchingProduct.items_category,
        address: orderItem.notes,
        date: orderItem.date,
        time: orderItem.time,
        status: orderItem.status,
        paid: orderItem.paid,
      };
    });

  mergedArray &&
    mergedArray.sort((a, b) => {
      const timeA = new Date(`2022-01-01 ${a.time}`);
      const timeB = new Date(`2022-01-01 ${b.time}`);

      return timeB - timeA;
    });

  return (
    <div className="flex flex-col gap-4 py-6">
      {mergedArray ? (
        mergedArray.map((item, i) => {
          return (
            <div
              key={i}
              className="transition-all pt-4 cursor-default duration-500 hover:translate-y-2 overflow-hidden w-full lg:mx-4 bg-neutral-50 rounded-lg shadow-xl"
            >
              <div className=" flex lg:flex-row flex-col justify-center items-center gap-4 px-4">
                <div className="flex justify-start gap-6 w-full">
                  <img
                    src={getImageUrl(item.image)}
                    alt=""
                    className="w-[8rem]"
                  />
                  <div>
                    <h6 className="text-sm text-gray-700 font-bold">
                      # {item.orderId}
                    </h6>
                    <p className="text-gray-500 text-[14px]">{item.date}</p>
                    <div className="mt-2">
                      <p className="font-bold text-primary-bg">
                        {item.ItemName}
                      </p>
                      <p className="text-gray-600">{item.description}</p>
                      <p className="text-primary-bg font-semibold">
                        ₹ {item.price}{' '}
                        <span className="text-sm">
                          {item.weight}
                          {item.category === 'biscuits' ? 'pieces' : 'kg'}
                        </span>
                      </p>
                      <p className="text-primary-bg text-sm">
                        Quantity: {item.quantity}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <h4 className="text-lg font-semibold">{item.address.name}</h4>
                  <p className="text-gray-500">{item.address.contact}</p>
                  <p className="text-gray-500">{item.address.email}</p>
                  <p className="text-gray-500">{item.address.address}</p>
                </div>
              </div>

              <div className="flex items-center p-4 justify-between">
                <p>
                  paid: <span className="font-semibold">₹ {item.paid}</span>
                </p>
                <p className="font-bold tracking-wide text-green-600">
                  {item.status}
                </p>
              </div>
            </div>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserOrders;
