import { useEffect } from 'react';
import './App.css';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';

import { UseAuthContext } from './context/useAuthContext';

import { FetchSanity } from './helpers/FetchSanity';

import Home from './pages/Home';
import Product from './pages/Product';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Profile from './pages/Profile';
import Category from './pages/Category';
import Favorites from './pages/Favorites';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import About from './pages/About';
import PageNotFound from './pages/PageNotFound';

import Navbar from './shared/Navbar';
import Contact from './shared/Contact';
import Footer from './shared/Footer';
import TermsAndConditions from './pages/TermsAndConditions';
import ReturnsCancellations from './pages/ReturnsCancellations';
import ShippingPolicy from './pages/ShippingPolicy';

function App() {
  const { user } = UseAuthContext();
  const { fetchHero, fetchItems, fetchGallery, Hero, Items, Gallery } =
    FetchSanity();

  useEffect(() => {
    fetchHero();
    fetchItems();
    fetchGallery();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BrowserRouter
    // basename={
    //   process.env.NODE_ENV === 'development' ? '' : 'srinaveenasweets'
    // }
    >
      <Navbar Items={Items} />
      <Routes>
        <Route
          path="/"
          element={<Home Hero={Hero} Items={Items} GalleryImages={Gallery} />}
        />

        <Route path="/:category" element={<Category />} />

        <Route path="/:category/:productId" element={<Product />} />

        <Route path="/cart" element={user ? <Cart /> : <Navigate to="/" />} />
        <Route
          path="/favorites"
          element={user ? <Favorites /> : <Navigate to="/" />}
        />
        <Route path="cart/checkout" element={<Checkout />} />
        <Route
          path="/profile"
          element={user ? <Profile /> : <Navigate to="/" />}
        />

        <Route path="/about" element={<About />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

        <Route
          path="/return-cancellations"
          element={<ReturnsCancellations />}
        />

        <Route path="/shipping-policy" element={<ShippingPolicy />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Contact />
      <Footer Items={Items} />
    </BrowserRouter>
  );
}

export default App;
