import React, { useEffect } from 'react';

import { UseOrderContext } from '../context/useOrderContext';

import HeroBanner from './Home/Hero';
import Sweets from './Home/Sweets';
import Cakes from './Home/Cakes';
import Gallery from './Home/Gallery';
import HotItems from './Home/HotItems';
import Biscuits from './Home/Biscuits';
import OrderPlaced from './Home/OrderPlaced';
import Services from './Home/Services';

const Home = ({ Hero, Items, GalleryImages }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  const { order } = UseOrderContext();

  return (
    <div>
      {Hero ? (
        <HeroBanner Hero={Hero} />
      ) : (
        <div className="flex flex-col bg-neutral-300 w-full h-screen animate-pulse rounded-xl p-12">
          <div className="bg-neutral-400/50 w-full h-32 animate-pulse rounded-md"></div>
        </div>
      )}
      <Sweets
        sweets={
          Items && Items.filter((item) => item.items_category === 'sweets')
        }
      />

      <Cakes
        Cakes={
          Items && Items.filter((items) => items.items_category === 'cakes')
        }
      />

      <HotItems
        HotItems={
          Items && Items.filter((items) => items.items_category === 'hot_items')
        }
      />

      <Biscuits
        Biscuits={
          Items && Items.filter((items) => items.items_category === 'biscuits')
        }
      />

      <Gallery itemData={GalleryImages} />

      {/* <About data={Hero} /> */}
      <Services />

      {order && <OrderPlaced />}
    </div>
  );
};

export default Home;
