import * as React from 'react';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from '@mui/base';
import { CiMail, CiPhone, CiTimer } from 'react-icons/ci';
import validator from 'validator';
import emailjs from '@emailjs/browser';

export default function Contact() {
  const lat = 16.327911;
  const lon = 80.437895;

  const mapSrc = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`;

  const [email, setEmail] = React.useState('');
  const [message, setMesaage] = React.useState('');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');

  const form = React.useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validator.isEmail(email)) {
      alert('Please enter a valid email');
      return false;
    }

    if (message === '') {
      alert("Message can't be empty");
      return;
    }

    if (name === '') {
      alert('Enter your name');
      return;
    }

    if (!validator.isMobilePhone(phone, 'any', { strictMode: false })) {
      alert('Invalid phone number');
      return;
    }

    emailjs
      .sendForm(
        'service_mwg0y3a',
        'template_xloc34t',
        form.current,
        'QO8EmqeKYHw_T80LO'
      )
      .then(
        (result) => {
          console.log(result.text);

          document.getElementById('name').value = '';
          document.getElementById('email').value = '';
          document.getElementById('phone').value = '';
          document.getElementById('message').value = '';
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="my-4">
      <h3
        className={`cakesHead font-googleNunito text-[3rem] text-center my-5 bg-cover bg-center w-full`}
      >
        Contact us
      </h3>

      <div
        className={`text-gray-600 body-font relative ${
          window.innerWidth > 768 ? 'px-12' : 'px-6 flex flex-col-reverse gap-6'
        }`}
      >
        <div
          className={`${
            window.innerWidth > 768 ? 'absolute' : 'h-[40vh] '
          } inset-0 bg-gray-300`}
        >
          <iframe
            src={mapSrc}
            id="iframeId"
            height="100%"
            width="100%"
            frameBorder="0"
            title="map"
            scrolling="no"
          ></iframe>
        </div>
        <div className={`container py-[0px] md:py-[40px] flex `}>
          <form
            className={`lg:w-1/3 md:w-1/2 bg-white rounded-lg p-6 flex flex-col md:ml-auto w-full relative z-10 shadow-md`}
            ref={form}
          >
            <h2 className="text-gray-900 pb-4 text-lg mb-1 font-medium title-font">
              Leave a message
            </h2>
            <div className="relative flex flex-col gap-2 mb-4">
              <TextField
                label="Name"
                variant="outlined"
                id="name"
                name="name"
                onChange={(e) => setName(e.target.value)}
                required
                className=""
              />
              <TextField
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <TextField
                id="phone"
                name="phone"
                label="Phone"
                variant="outlined"
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <TextareaAutosize
                aria-label="empty textarea"
                placeholder="Message"
                variant="outlined"
                id="message"
                name="message"
                minRows={3}
                onChange={(e) => setMesaage(e.target.value)}
                className="border-[1px] rounded-md p-4 border-gray-400"
                required
              />
              <button
                className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                onClick={handleSubmit}
              >
                Send
              </button>
            </div>

            <div className="mt-4">
              <label className="flex items-center gap-2 leading-7 text-sm">
                <CiMail className="text-[18px]" />
                naveenasweets@gmail.com
              </label>
              <label className="flex items-center gap-2 leading-7 text-sm">
                <CiPhone className="text-[18px]" />
                +91-9618335976
              </label>
              <label className="flex items-center gap-2 leading-7 text-sm">
                <CiTimer className="text-[18px]" />
                Opens daily 9:00 am to 10:30 pm
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
