import React, { useEffect } from 'react';

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <div className="p-6 lg:px-[8rem] font-googleterms">
      <div className="flex flex-col gap-4">
        <div>
          <h1 className="font-bold text-xl mb-8">Terms and Conditions</h1>

          <p>Welcome to Sri Naveena Sweets and Bakery!</p>
        </div>

        <div className="flex flex-col gap-2">
          <p>
            These terms and conditions outline the rules and regulations for the
            use of Sri Naveena Sweets and Bakery’s Website, located at{' '}
            <a href="www.srinaveenasweets.com" target="_self">
              www.srinaveenasweets.com
            </a>
          </p>

          <p>
            By accessing this website, we assume you accept these terms and
            conditions. If you do not agree to abide by all of the terms and
            conditions stated on this page, please refrain from using Sri
            Naveena Sweets and Bakery.
          </p>

          <p>
            The following terms apply to these Terms and Conditions, Privacy
            Statement, and Disclaimer Notice, and any agreements herein:
            “Client”, “You” and “Your” refers to you, the user of this website,
            compliant with the Company’s terms and conditions. “The Company”,
            “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”,
            “Parties”, or “Us”, refers to both the Client and ourselves. All
            terms refer to the offer, acceptance, and consideration of payment
            necessary to undertake the process of our assistance to the Client
            in the most appropriate manner for the express purpose of meeting
            the Client’s needs, in respect to provision of the Company’s stated
            services, in accordance with and subject to, prevailing law of
            Netherlands. Any use of the above terminology or other words in the
            singular, plural, capitalization, and/or he/she or they, are taken
            as interchangeable and therefore as referring to the same.
          </p>
        </div>

        <div>
          <h4 className="font-semibold">License</h4>
          <div className="flex flex-col gap-2">
            <p>
              Unless otherwise indicated, Sri Naveena Sweets and Bakery and/or
              its licensors retain the intellectual property rights for all
              content on Sri Naveena Sweets and Bakery. All intellectual
              property rights are reserved. You may access this content from Sri
              Naveena Sweets and Bakery for personal use, subject to
              restrictions outlined in these terms and conditions.
            </p>
          </div>
        </div>

        <div>
          <h4 className="font-semibold">You are prohibited from:</h4>
          <div className="flex flex-col gap-2">
            <p>
              Republishing material from Sri Naveena Sweets and Bakery Selling,
              renting, or sub-licensing material from Sri Naveena Sweets and
              Bakery Reproducing, duplicating, or copying material from Sri
              Naveena Sweets and Bakery Redistributing content from Sri Naveena
              Sweets and Bakery
            </p>

            <p>
              This Agreement is effective as of the date herein. Our Terms and
              Conditions were generated with the assistance of a Free Terms and
              Conditions Generator.
            </p>

            <p>
              Parts of this website provide opportunities for users to post and
              exchange opinions and information in designated areas. Sri Naveena
              Sweets and Bakery does not filter, edit, publish, or review
              Comments prior to their appearance on the website. Comments do not
              necessarily reflect the views and opinions of Sri Naveena Sweets
              and Bakery, its agents, or affiliates. To the extent permitted by
              applicable laws, Sri Naveena Sweets and Bakery shall not be held
              liable for Comments or any liability, damages, or expenses
              resulting from the use of Comments on this website.
            </p>

            <p>
              Sri Naveena Sweets and Bakery reserves the right to monitor all
              Comments and remove any Comments deemed inappropriate, offensive,
              or violating these Terms and Conditions.
            </p>
          </div>
        </div>

        <div>
          <h4 className="font-semibold">You warrant and represent that:</h4>
          <div className="flex flex-col gap-2">
            <p>
              ou have the right to post Comments on our website and possess all
              necessary licenses and consents to do so; Comments do not infringe
              upon any intellectual property rights, including copyright,
              patent, or trademark, of any third party; Comments do not contain
              defamatory, libelous, offensive, indecent, or otherwise unlawful
              material that invades privacy; Comments will not be utilized for
              solicitation, promotion of business, or commercial activities.
            </p>

            <p>
              By posting Comments, you grant Sri Naveena Sweets and Bakery a
              non-exclusive license to use, reproduce, edit, and authorize
              others to use, reproduce, and edit your Comments in all forms,
              formats, or media.
            </p>
          </div>
        </div>

        <div>
          <h4 className="font-semibold">Content Liability</h4>
          <div className="flex flex-col gap-2">
            <p>
              We shall not be held responsible for any content appearing on your
              Website. You agree to indemnify and defend us against all claims
              arising on your Website. No links should appear on any Website
              that could be interpreted as libelous, obscene, or criminal, or
              that infringes upon, violates, or advocates the infringement or
              other violation of any third-party rights.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <h4 className="font-semibold">Your Privacy</h4>

          <p>Please review our Privacy Policy.</p>
        </div>

        <div>
          <h4 className="font-semibold">Reservation of Rights</h4>
          <div className="flex flex-col gap-2">
            <p>
              We reserve the right to request the removal of any links to our
              Website. You agree to immediately remove all links to our Website
              upon request. We also reserve the right to amend these terms and
              conditions and our linking policy at any time. By continuing to
              link to our Website, you agree to abide by and follow these
              linking terms and conditions.
            </p>
          </div>
        </div>

        <div>
          <h4 className="font-semibold">Removal of links from our website</h4>
          <div className="flex flex-col gap-6">
            <p>
              If you find any links on our Website that are offensive for any
              reason, please feel free to contact us. We will consider requests
              to remove links, but we are not obligated to do so or to respond
              to you directly.
            </p>

            <p>
              We do not guarantee the accuracy or completeness of information on
              this website, nor do we promise to ensure its availability or that
              its content is kept up to date.
            </p>
          </div>
        </div>

        <div>
          <h4 className="font-semibold">Disclaimer</h4>
          <div className="flex flex-col gap-6">
            <div>
              <p>
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties, and conditions relating to our
                website and the use of this website. This disclaimer does not:
              </p>
              <ul>
                <li>
                  limit or exclude our or your liability for death or personal
                  injury;
                </li>
                <li>
                  limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation;
                </li>
                <li>
                  limit any of our or your liabilities in any way that is not
                  permitted under applicable law; or
                </li>
                <li>
                  exclude any of our or your liabilities that may not be
                  excluded under applicable law.
                </li>
              </ul>
            </div>

            <p>
              The limitations and prohibitions of liability set in this Section
              and elsewhere in this disclaimer: (a) are subject to the preceding
              paragraph; and (b) govern all liabilities arising under the
              disclaimer, including liabilities arising in contract, in tort and
              for breach of statutory duty.
            </p>

            <p>
              As long as the website and the information and services on the
              website are provided free of charge, we will not be liable for any
              loss or damage of any nature.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
