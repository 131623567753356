import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import { UseAuthContext } from '../context/useAuthContext';

import FetchMongo from '../helpers/FetchMongo';
import { FetchSanity } from '../helpers/FetchSanity';

import CartItemCard from '../components/Cart/CartItemCard';
import Banner from '../components/Banner/Banner';

const Cart = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const cart = process.env.PUBLIC_URL + '/assets/cart.jpg';

  const { fetchCartData, cartData } = FetchMongo();
  const { fetchItems, Items } = FetchSanity();

  const { user } = UseAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [totalSum, setTotalSum] = useState();

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = () => {
    if (user) {
      fetchCartData();
      fetchItems();
    }
  };

  const mergedArray =
    cartData &&
    cartData.cartItems
      .filter(
        (cartItem) =>
          Items &&
          Items.some((product) => product.item_id === cartItem.productId)
      )
      .map((cartItem) => {
        const matchingProduct = Items.find(
          (product) => product.item_id === cartItem.productId
        );
        return {
          quantity: cartItem.quantity,
          item_id: matchingProduct.item_id,
          name: matchingProduct.name,
          description: matchingProduct.decription,
          images: matchingProduct.images,
          price: matchingProduct.sale_price,
          weight: matchingProduct.weight,
          category: matchingProduct.items_category,
        };
      });

  useEffect(() => {
    const totalSum =
      mergedArray &&
      mergedArray.reduce((sum, item) => {
        const totalPrice = item.quantity * item.price; // Adjust property name based on your actual data
        return sum + totalPrice;
      }, 0);

    setTotalSum(totalSum);
  }, [mergedArray]);

  const convenience = totalSum * 0.04;
  const grandTotal = totalSum + convenience;

  return mergedArray && mergedArray.length > 0 ? (
    <>
      {/* <Banner title={'Cart'} /> */}
      <div className="bg-gray-100 py-8 lg:min-h-[100vh]">
        <div className="container mx-auto px-4">
          <h1 className="text-2xl font-semibold mb-4">Shopping Cart</h1>
          <div className="flex flex-col md:flex-row gap-4">
            <CartItemCard
              mergedArray={mergedArray}
              fetchCartData={fetchCartData}
            />
            <div className="md:w-1/4">
              <div className="bg-white rounded-lg shadow-md p-6 flex flex-col">
                <h2 className="text-lg font-semibold mb-4">Summary</h2>
                <div className="flex justify-between mb-2">
                  <span>Subtotal</span>
                  <span>₹ {totalSum && totalSum.toFixed(1)}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span>Convenience charges</span>
                  <span>₹ {convenience.toFixed(2)}</span>
                </div>
                <hr className="my-2" />
                <div className="flex justify-between mb-2">
                  <span className="font-semibold">Total</span>
                  <span className="font-semibold">₹ {grandTotal}</span>
                </div>
                {grandTotal > 200 ? (
                  <Link
                    to="/cart/checkout"
                    className="bg-blue-500 text-white py-2 px-4 rounded-lg mt-4 w-full text-center"
                    state={{ data: mergedArray }}
                  >
                    Checkout
                  </Link>
                ) : (
                  <div
                    className="bg-blue-300 text-white py-2 px-4 rounded-lg mt-4 w-full text-center cursor-pointer"
                    onClick={() =>
                      enqueueSnackbar('Cart value should be above 200/-', {
                        variant: 'warning',
                        autoHideDuration: 2000,
                      })
                    }
                  >
                    Checkout
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <Banner title={'Cart'} />
      <div className="lg:min-h-screnn flex justify-center items-center lg:p-12 my-12">
        <div className="w-1/2 flex flex-col items-center">
          <h4 className="lg:text-[4rem] text-[2rem] text-nowrap font-googleBiscuits">
            No items in cart
          </h4>
          <img src={cart} alt="" className="w-full h-full" />
        </div>
      </div>
    </>
  );
};

export default Cart;
