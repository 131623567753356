import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import FetchMongo from '../helpers/FetchMongo';

import UserInfo from '../components/Profile/UserInfo';
import UserAddress from '../components/Profile/UserAddress';
import UserOrders from '../components/Profile/UserOrders';
import Banner from '../components/Banner/Banner';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Profile() {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const { fetchUserData, userData } = FetchMongo();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    fetchUserData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* <Banner title={'Profile'} /> */}
      <div className="lg:px-[12rem] lg:py-12 py-4 flex flex-col gap-4 bg-[#e9e9e9] lg:min-h-screen">
        <Card
          sx={{
            padding: '1.4rem',
            gap: '1.2em',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className="w-24 h-24 rounded-[50%] overflow-hidden">
            <img
              className="w-full h-full"
              src={userData && userData.displayPic}
              alt=""
            />
          </div>
          <p className="font-googleNunito lg:text-[2rem] text-[1.6rem]">
            {userData && userData.name}
          </p>
        </Card>
        <Card
          sx={
            window.innerWidth > 768
              ? {
                  flexGrow: 1,
                  bgcolor: 'background.paper',
                  display: 'grid',
                  gridTemplateColumns: '1fr 5fr',
                  paddingX: '2rem',
                }
              : { width: '100%', typography: 'body1', paddingX: '1.2rem' }
          }
        >
          <Tabs
            orientation={`${
              window.innerWidth > 768 ? 'vertical' : 'horizontal'
            }`}
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label="Info" {...a11yProps(0)} sx={{ alignItems: 'end' }} />
            <Tab label="Orders" {...a11yProps(1)} sx={{ alignItems: 'end' }} />
            <Tab
              label="Addresses"
              {...a11yProps(2)}
              sx={{ alignItems: 'end' }}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <UserInfo
              name={userData && userData.name}
              email={userData && userData.email}
              phone={userData && userData.phoneNumber}
              fetchData={() => fetchUserData()}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UserOrders />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <UserAddress />
          </TabPanel>
        </Card>
      </div>
    </>
  );
}
