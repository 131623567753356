import { useState } from 'react';
import { UseAuthContext } from '../context/useAuthContext';
import { useLogout } from '../context/useLogout';

const FetchMongo = () => {
  const { user } = UseAuthContext();
  const { logout } = useLogout();
  const [userData, setUserdata] = useState();
  const [cartData, setCartData] = useState();
  const [favData, setFavData] = useState();
  const [addresses, setAddresses] = useState();
  const [address, setAddress] = useState();
  const [orders, setOrders] = useState();

  const BACKEND_URL =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000'
      : 'https://srinaveenasweets.onrender.com';

  const fetchUserData = async () => {
    const response = await fetch(`${BACKEND_URL}/api/user/data`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();

    if (!response.ok) {
      // console.log(response);
      logout();
    }
    if (response.ok) {
      setUserdata(json.user);
    }
  };

  const fetchCartData = async () => {
    const response = await fetch(`${BACKEND_URL}/api/user/cart`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();

    if (!response.ok) {
      console.log(json);
    }
    if (response.ok) {
      setCartData(json);
    }
  };

  const fetchFavData = async () => {
    const response = await fetch(`${BACKEND_URL}/api/user/favorites`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();

    if (!response.ok) {
      console.log(json);
    }
    if (response.ok) {
      setFavData(json.favorites);
    }
  };

  const fetchAddresses = async () => {
    const response = await fetch(`${BACKEND_URL}/api/user/address`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();

    if (!response.ok) {
      console.log(json);
    }
    if (response.ok) {
      setAddresses(json.addresses);
    }
  };

  const fetchAddress = async ({ id }) => {
    const response = await fetch(`${BACKEND_URL}/api/user/address/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();

    if (!response.ok) {
      console.log(json);
    }
    if (response.ok) {
      setAddress(json.address);
    }
  };

  const fetchOrders = async () => {
    const response = await fetch(`${BACKEND_URL}/api/order`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();

    if (!response.ok) {
      console.log(json);
    }
    if (response.ok) {
      setOrders(json.orders);
    }
  };

  return {
    fetchUserData,
    fetchCartData,
    fetchFavData,
    fetchAddresses,
    fetchAddress,
    fetchOrders,
    userData,
    cartData,
    favData,
    addresses,
    address,
    orders,
  };
};

export default FetchMongo;
