import React from 'react';
import { Link } from 'react-router-dom';

import FetchImageUrl from '../../helpers/FetchImageUrl';

const HotItems = ({ HotItems }) => {
  const { getImageUrl } = FetchImageUrl();
  const backgroundImagePath = process.env.PUBLIC_URL + '/assets/hot_items.jpg';

  return (
    <div className="relative flex flex-col items-center">
      <h3
        className={`cakesHead font-googleHotItems ${
          window.innerWidth > 768 ? 'text-[5rem]' : 'text-[4em]'
        } text-center my-5 text-white bg-cover bg-center w-full`}
        style={{ backgroundImage: `url(${backgroundImagePath})` }}
      >
        Hot Items
      </h3>

      <div
        className={`grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-5 justify-center items-center `}
      >
        {HotItems &&
          HotItems.slice(0, 7).map((item, i) => {
            return (
              <Link
                to={`hot-items/${item.item_id}`}
                className="card w-[170px] h-[200px] px-6 py-8 hover:shadow-[0_8px_50px_#23232333] border-[2px] border-[color:var(--items-border-bg)] hover:border-solid"
                key={i}
              >
                <div className="card-image overflow-hidden flex flex-col justify-center items-center">
                  <img
                    className="w-full h-full"
                    src={getImageUrl(item.images[0])}
                    alt=""
                  />
                </div>
                <div className="card-info translate-y-[200%] flex justify-around w-full opacity-0 font-googleNunito font-[700] tracking-[1px] text-[color:var(--font-color)]">
                  {item.name}
                </div>
              </Link>
            );
          })}
        <Link
          to="/hot_items"
          className="card text-[1.6em] text-center tracking-[2px] text-[color:var(--buttons-bg)] font-[700] flex items-center justify-center uppercase w-[170px] h-[200px] px-6 py-8 hover:shadow-[0_8px_50px_#23232333] border-[2px] border-[color:var(--items-border-bg)] hover:border-solid hover:tracking-[3.6px]"
        >
          VIEW ALL
        </Link>
      </div>
    </div>
  );
};

export default HotItems;
