import { useState } from 'react';

import { client } from '../client';

export const FetchSanity = () => {
  const [Hero, setHero] = useState();
  const [Items, setItems] = useState([]);
  const [Gallery, setGallery] = useState();

  const fetchHero = async () => {
    try {
      const Hero = await client.fetch('*[_type == "banner"]');
      setHero(Hero);
    } catch (error) {
      console.error('Error fetching first image:', error);
    }
  };

  const fetchItems = async () => {
    try {
      const Items = await client.fetch('*[_type == "items"]');
      setItems(Items);
    } catch (error) {
      console.error('Error fetching first image:', error);
    }
  };

  const fetchGallery = async () => {
    try {
      const Images = await client.fetch('*[_type == "gallery"]');
      setGallery(Images);
    } catch (error) {
      console.error('Error fetching first image:', error);
    }
  };

  return {
    fetchHero,
    fetchItems,
    fetchGallery,
    Hero,
    Items,
    Gallery,
  };
};
