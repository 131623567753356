import Orders from './Orders';

const OrderConfirm = () => {
  const { handlePayment } = Orders();

  const confirm = async ({ orderDetails }) => {
    const amount = orderDetails.totalPrice * 100;
    const currency = 'INR';

    const currentDate = new Date();
    const date = currentDate.getDate();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear() % 100;
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');
    const mill = currentDate.getMilliseconds().toString().padStart(2, '0');

    const receipt = `${date}${
      month + 1
    }${year}${hours}${minutes}${seconds}${mill}`;

    const fullAddress =
      orderDetails.address.fullAddress +
      ' ' +
      orderDetails.address.state +
      ' ' +
      orderDetails.address.pincode;

    const notes = {
      name: orderDetails.address.fullname,
      email: orderDetails.address.email,
      contact: orderDetails.address.mobile,
      address: fullAddress,
    };

    const items = orderDetails.items;

    await handlePayment({ amount, currency, receipt, notes, items });

    // setAllDetails({ amount, currency, receipt, notes, items });
  };

  return { confirm };
};

export default OrderConfirm;
