import React from 'react';
import { Link } from 'react-router-dom';

import FetchImageUrl from '../../helpers/FetchImageUrl';
import PostMongo from '../../helpers/PostMongo';

const CartItemCard = ({ mergedArray, fetchCartData }) => {
  const { getImageUrl } = FetchImageUrl();
  const { updateCartData, deleteCartItem } = PostMongo();

  return (
    <div className="md:w-3/4">
      <div className="bg-white rounded-lg shadow-md lg:p-6 p-3 mb-4">
        <table className="w-full">
          <thead>
            <tr>
              <th className="text-left font-semibold">Product</th>
              <th className="text-left font-semibold">Price</th>
              <th className="text-center font-semibold">Quantity</th>
              <th className="text-left font-semibold">Total</th>
            </tr>
          </thead>
          <tbody>
            {mergedArray.map((item, i) => {
              return (
                <tr key={i}>
                  <td className="lg:py-4">
                    <div className="lg:flex gap-4 items-center">
                      <Link to={`/${item.category}/${item.item_id}`}>
                        <img
                          className="w-24"
                          src={getImageUrl(item.images[0])}
                          alt=""
                        />
                      </Link>
                      <div>
                        <p className="font-semibold">{item.name}</p>
                        <p className=" text-gray-700 text-xs">
                          {item.description}
                        </p>
                        <p className=" text-gray-700 text-xs">
                          {item.weight}{' '}
                          {item.category === 'biscuits' ? 'pieces' : 'kg'}
                        </p>
                        <div
                          className="font-semibold mt-4 hover:text-red-500 text-gray-500 w-fit text-xs cursor-pointer"
                          onClick={() =>
                            deleteCartItem(item.item_id).then(() =>
                              fetchCartData()
                            )
                          }
                        >
                          Remove
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="lg:py-4 text-nowrap">₹ {item.price}.00</td>
                  <td className="lg:py-4">
                    <div className="flex flex-col-reverse lg:flex-row justify-center items-center">
                      <button
                        className="border rounded-md py-2 px-4 text-[1.2rem]"
                        onClick={() => {
                          item.quantity !== 1 &&
                            updateCartData({
                              productId: item.item_id,
                              quantity: item.quantity - 1,
                            }).then(() => fetchCartData());
                        }}
                      >
                        -
                      </button>
                      <p className="text-center p-2 lg:w-8">{item.quantity}</p>
                      <button
                        className="border rounded-md py-2 px-4 text-[1.2rem]"
                        onClick={() =>
                          item.quantity !== 9 &&
                          updateCartData({
                            productId: item.item_id,
                            quantity: item.quantity + 1,
                          }).then(() => fetchCartData())
                        }
                      >
                        +
                      </button>
                    </div>
                  </td>
                  <td className="py-4">₹ {item.quantity * item.price}.00</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CartItemCard;
