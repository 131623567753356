import { OrderContext } from '../context/orderContext';
import { useContext } from 'react';

export const UseOrderContext = () => {
  const context = useContext(OrderContext);

  if (!context) {
    throw Error('useAuthContext must be used inside an OrderContextProvider');
  }

  return context;
};
