import React from 'react';

const ViewAll = () => {
  return (
    <div className="flex relative">
      <button className="button cursor-pointer text-xl font-semibold text-[color:var(--buttons-bg)] transition-all duration-[0.3s] ease-[ease] px-[1.5em] py-[0.5em] rounded-lg border-[none] hover:tracking-[2px]">
        View all
      </button>
      <span className="fill-[color:var(--secondary-bg)] absolute pointer-events-none w-6 transition-all duration-[0.3s] ease-[cubic-bezier(0.645,0.045,0.355,1)] right-0 top-1/4 first">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
        </svg>
      </span>
      <span className="fill-[color:var(--secondary-bg)] absolute pointer-events-none w-6 transition-all duration-[0.3s] ease-[cubic-bezier(0.645,0.045,0.355,1)] right-0 top-1/4 second">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
        </svg>
      </span>
      <span className="fill-[color:var(--secondary-bg)] absolute pointer-events-none w-6 transition-all duration-[0.3s] ease-[cubic-bezier(0.645,0.045,0.355,1)] right-0 top-1/4 third">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
        </svg>
      </span>
    </div>
  );
};

export default ViewAll;
