import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UseAuthContext } from '../context/useAuthContext';

import { FetchSanity } from '../helpers/FetchSanity';
import FetchMongo from '../helpers/FetchMongo';

import NavbarItems from '../components/Navbar/NavbarItems';
import Login from '../components/Navbar/Login';
import ProfileIcon from '../components/Navbar/ProfileIcon';
import Appbar from '../components/Navbar/Appbar';
import Search from '../components/Navbar/Search';
import Location from '../components/Navbar/Location';

const Navbar = () => {
  const logo_path = process.env.PUBLIC_URL + '/assets/Logo.png';
  const logo_pathh = process.env.PUBLIC_URL + '/assets/Logo2.png';

  const { user } = UseAuthContext();
  const { fetchUserData, userData } = FetchMongo();

  const { fetchItems, Items } = FetchSanity();

  useEffect(() => {
    fetchItems();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      fetchUserData();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full z-[999] py-4 flex flex-col items-center gap-4 lg:bg-[#ebe3c4]">
      <div className="lg:flex lg:justify-around lg:px-4 w-full">
        {window.innerWidth > 768 && (
          <Link to="/" className="w-[8em]">
            <img src={logo_path} alt="" className="w-full" />
          </Link>
        )}

        <div className="flex-col justify-end pb-4 lg:flex hidden">
          <NavbarItems />
        </div>

        <div className="flex flex-col gap-2 px-4 justify-evenly">
          <div className="flex lg:justify-end justify-between cursor-default pr-2">
            {window.innerWidth < 768 && (
              <Link to="/" className="w-[8rem]">
                <img src={logo_pathh} alt="" className="w-full" />
              </Link>
            )}
            <div className="flex gap-1 items-center justify-between font-[400] ">
              <Location />
              {user ? <ProfileIcon data={userData} /> : <Login />}
            </div>
          </div>
          <Search Items={Items} />
        </div>
      </div>
      <Appbar />
    </div>
  );
};

export default Navbar;
