import React from 'react';
import Glider from 'react-glider';
import 'glider-js/glider.min.css';

import { Link } from 'react-router-dom';

import FetchImageUrl from '../../helpers/FetchImageUrl';

import sweetscard from '../../assets/sweetscard.png';

const Sweets = ({ sweets }) => {
  const { getImageUrl } = FetchImageUrl();
  return (
    <div>
      <div className="w-full max-h-[1200px]">
        <div className="px-[20px] sm:px-[35px] lg:px-[60px] py-[20px] flex flex-col md:flex-row w-full justify-between max-h-[580px] md:max-h-[680px] gap-[10px]">
          <div className="md:basis-[40%] w-full h-full max-h-[580px] flex justify-end ">
            <div className=" mx-auto p-[10px] rounded-[5%] border-2 shadow-sm">
              <img
                src={sweetscard}
                alt="sweets"
                className="h-full w-full bg-cover"
              />
            </div>
          </div>
          <div className="md:basis-[55%] text-[14px] sm:text-[18px] lg:text-[20px] font-serif flex flex-col justify-center items-center md:items-start text-justify p-[5px]">
            <div className="max-w-[600px] flex justify-center items-center md:items-start flex-col text-center md:text-left ">
              <p className="py-[10px] font-googleNunito">
                Indulge in unparalleled sweetness with our exquisite treats,
                where every bite is a symphony of delightful flavors that will
                enchant your taste buds and leave you craving for more.
              </p>
              <div className="bg-white text-[2rem] font-bold">Sweets</div>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:px-16 px-3">
        <Glider
          className="glider-container"
          draggable
          hasArrows
          hasDots
          slidesToShow={window.innerWidth > 768 ? 3 : 1}
        >
          {sweets.map((data, i) => {
            const sellingprice = data.sale_price;
            const costprice = data.cost_price;
            const diff = sellingprice - costprice;

            const off = (diff / costprice) * 100;

            const product = data.item_id;
            const category = data.items_category;

            return (
              <div
                key={i}
                className="shadow-[0_0_10px_rgba(0,0,0,0.2)] overflow-hidden rounded-lg h-full"
              >
                <Link
                  to={`${category}/${product}`}
                  className="relative flex flex-col justify-between h-full"
                >
                  <div className="flex items-center h-full pb-8">
                    <img
                      src={getImageUrl(data.images[0])}
                      alt={data.name}
                      className="w-full h-full border-b-[#ddd] border-b border-solid"
                    />
                  </div>
                  <div className="px-[15px]">
                    <div className="text-lg m-0 font-googleNunito flex items-center gap-2">
                      {data.name}

                      <span className="text-sm text-[#555]">
                        {data.weight}kg
                      </span>
                    </div>
                    <div
                      className={`text-base mb-[5px] flex justify-between lg:gap-2 `}
                    >
                      <p className="text-[16px] m-0"> ₹ {data.sale_price}.00</p>
                      {data.cost_price > data.sale_price && (
                        <p className="text-[#888] text-[16px] line-through m-0">
                          ₹ {data.cost_price}.00
                        </p>
                      )}
                    </div>
                  </div>
                  {data.cost_price > data.sale_price && (
                    <div className="bg-[#32ea75] w-[2.7rem] h-[2.7rem] flex items-center justify-center text-[14px] bottom-20 right-2 py-2.5 absolute px-1 rounded-[50%] font-[500]">
                      {Math.ceil(off)}%
                    </div>
                  )}
                </Link>
              </div>
            );
          })}
        </Glider>
      </div>
    </div>
  );
};

export default Sweets;
