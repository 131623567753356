import React, { useState } from 'react';
import { IoMailOutline } from 'react-icons/io5';
import { CgProfile } from 'react-icons/cg';
import { MdOutlinePhoneAndroid } from 'react-icons/md';

import PostMongo from '../../helpers/PostMongo';

const UserInfo = ({ name, email, phone, fetchData }) => {
  const { updateUserDetails } = PostMongo();

  const [namee, setName] = useState(name);
  const [phoneNumber, setPhoneNumber] = useState(phone);

  const updateData = async () => {
    var regex = /^[6-9]\d{9}$/;

    // Test the input against the regular expression
    if (!phoneNumber) {
      await updateUserDetails({ name: namee, phoneNumber: phone });
      return;
    }

    if (regex.test(phoneNumber)) {
      await updateUserDetails({ name: namee, phoneNumber: phoneNumber }).then(
        () => fetchData()
      );
    } else {
      alert('Check the given Phone number', phoneNumber);
    }
  };

  return (
    <div className=" container shadow-md">
      <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center">
        <h2 className="md:w-1/3">Account</h2>
        <div className="md:w-2/3">
          <label className="text-sm text-gray-400">Email</label>
          <div className="w-full inline-flex border">
            <div className="flex justify-center text-[1.3rem] items-center w-1/12 bg-gray-100 bg-opacity-50">
              <IoMailOutline />
            </div>
            <input
              type="email"
              className="w-full focus:outline-none focus:text-gray-600 p-2"
              placeholder={email}
              disabled
            />
          </div>
        </div>
      </div>

      <hr />
      <div className="md:inline-flex justify-between space-y-4 md:space-y-0  w-full p-4 text-gray-500 items-center">
        <h2 className="md:w-1/3 ">Personal info</h2>
        <div className="md:w-2/3 space-y-5">
          <div>
            <label className="text-sm text-gray-400">Name</label>
            <div className="w-full inline-flex border">
              <div className="w-1/12 text-[1.3rem] flex justify-center items-center bg-gray-100">
                <CgProfile />
              </div>
              <input
                type="text"
                className="w-11/12 focus:outline-none focus:text-gray-600 p-2"
                placeholder={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label className="text-sm text-gray-400">Phone number</label>
            <div className="w-full inline-flex border">
              <div className="flex text-[1.3rem] justify-center items-center w-1/12 bg-gray-100">
                <MdOutlinePhoneAndroid />
              </div>
              <input
                type="text"
                className="w-11/12 focus:outline-none focus:text-gray-600 p-2"
                placeholder={phone ? phone : 'Ex: 9XXXXXXXX'}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="text-center">
            <button
              className="text-white w-full justify-center rounded-md text-center bg-blue-600 py-2 px-4 inline-flex items-center focus:outline-none md:float-right"
              onClick={() => updateData()}
            >
              <svg
                fill="none"
                className="w-4 text-white mr-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
