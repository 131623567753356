import React, { useState } from 'react';

import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

import FetchImageUrl from '../../helpers/FetchImageUrl';

const ProductImage = ({ productInfo }) => {
  const { getImageUrl } = FetchImageUrl();
  const [currImg, setCurrImg] = useState(0);

  return (
    <div className="relative lg:w-[50%] flex flex-col gap-4">
      <button
        type="button"
        className="absolute left-0 top-[35%] flex aspect-square w-10 text-center items-center justify-center rounded-full bg-transparent text-4xl lg:-left-10 lg:top-[32%] lg:w-16"
        onClick={() =>
          setCurrImg(
            currImg === 0 ? productInfo.images.length - 1 : currImg - 1
          )
        }
      >
        <FaAngleLeft />
      </button>

      <div className="">
        <img
          src={getImageUrl(productInfo.images[currImg])}
          alt={productInfo.name}
          className="block w-full lg:rounded-xl"
        />
      </div>

      <button
        type="button"
        className="absolute right-0 top-[35%] flex aspect-square w-10 items-center justify-center rounded-full bg-transparent text-4xl lg:-right-10 lg:top-[32%] lg:w-16"
        onClick={() =>
          setCurrImg(currImg >= productInfo.images.length - 1 ? 0 : currImg + 1)
        }
      >
        <FaAngleRight />
      </button>

      <div className="noscrollbar overflow-x-scroll flex md:w-full md:items-center md:justify-evenly gap-3 justify-start">
        {productInfo.images.map((image, index) => (
          <div
            key={index}
            className={`rounded-xl ${
              index === currImg && 'outline outline-primary'
            }`}
          >
            <img
              src={getImageUrl(image)}
              alt=""
              className={`w-24 rounded-lg transition-all duration-300 hover:cursor-pointer hover:opacity-40 lg:w-[88px] ${
                index === currImg && 'opacity-40'
              }`}
              onClick={() => setCurrImg(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductImage;
