import { useSnackbar } from 'notistack';
import { UseAuthContext } from '../context/useAuthContext';

const PostMongo = () => {
  const { user } = UseAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const BACKEND_URL =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000'
      : 'https://srinaveenasweets.onrender.com';

  const updateCartData = async ({ productId, quantity }) => {
    // console.log(productId, quantity);
    const response = await fetch(`${BACKEND_URL}/api/user/cart`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        productId,
        quantity,
      }),
    });

    if (!response.ok) {
      enqueueSnackbar('Something went wrong!', {
        variant: 'warning',
        autoHideDuration: 2000,
      });
    }
    if (response.ok) {
      enqueueSnackbar('Cart updated!', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
  };

  const updateAddress = async (address) => {
    const aId = address.aId;
    const fullname = address.fullname;
    const mobile = address.mobile;
    const email = address.email;
    const fullAddress = address.fullAddress;
    const landmark = address.landmark;
    const state = address.state;
    const pincode = address.pincode;

    const response = await fetch(`${BACKEND_URL}/api/user/address`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        aId,
        fullname,
        mobile,
        email,
        fullAddress,
        landmark,
        state,
        pincode,
      }),
    });

    if (!response.ok) {
      enqueueSnackbar('Something went wrong!', {
        variant: 'warning',
        autoHideDuration: 2000,
      });
    }
    if (response.ok) {
      enqueueSnackbar('Address updated!', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
  };

  const updateUserDetails = async ({ name, phoneNumber }) => {
    // console.log(name, phoneNumber);
    const response = await fetch(`${BACKEND_URL}/api/user/data`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        name,
        phoneNumber,
      }),
    });

    if (!response.ok) {
      enqueueSnackbar('Something went wrong!', {
        variant: 'warning',
        autoHideDuration: 2000,
      });
    }
    if (response.ok) {
      enqueueSnackbar('User Info updated!', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
  };

  const deleteCartItem = async (productId) => {
    // console.log(productId);
    const response = await fetch(`${BACKEND_URL}/api/user/cart`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        productId,
      }),
    });

    if (!response.ok) {
      enqueueSnackbar('Something went wrong!', {
        variant: 'warning',
        autoHideDuration: 2000,
      });
    }
    if (response.ok) {
      enqueueSnackbar('Item deleted!', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
  };

  const addFavorite = async (productId) => {
    console.log(productId);
    const response = await fetch(`${BACKEND_URL}/api/user/favorites`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        productId,
      }),
    });

    if (!response.ok) {
      enqueueSnackbar('Something went wrong!', {
        variant: 'warning',
        autoHideDuration: 2000,
      });
    }
    if (response.ok) {
      enqueueSnackbar('Item added to Favorites!', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
  };

  const deleteFavItem = async (productId) => {
    // console.log(productId);
    const response = await fetch(`${BACKEND_URL}/api/user/favorites`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        productId,
      }),
    });

    if (!response.ok) {
      enqueueSnackbar('Something went wrong!', {
        variant: 'warning',
        autoHideDuration: 2000,
      });
    }
    if (response.ok) {
      enqueueSnackbar('Item deleted!', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
  };

  const deleteAddress = async (aId) => {
    const response = await fetch(`${BACKEND_URL}/api/user/address`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        aId,
      }),
    });

    if (!response.ok) {
      enqueueSnackbar('Something went wrong!', {
        variant: 'warning',
        autoHideDuration: 2000,
      });
    }
    if (response.ok) {
      enqueueSnackbar('Address deleted!', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
  };

  return {
    updateCartData,
    updateUserDetails,
    deleteCartItem,
    updateAddress,
    addFavorite,
    deleteFavItem,
    deleteAddress,
  };
};

export default PostMongo;
