import React from 'react';
import shop from '../../assets/shop.jpg';

const AboutBanner = () => {
  return (
    <>
      <div className="w-[100%] min-h-[600px] lg:block mx-auto">
        <div className="relative">
          <div className="overflow-hidden absolute top-0 right-0 -z-20 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1560"
              height="328"
              viewBox="0 0 1560 328"
              fill="none"
            >
              <path
                d="M0 1V0H1560V1V328C814.126 68.5 983.666 328 0 1Z"
                fill="#FFF3F4"
              />
            </svg>
          </div>

          <div className="flex flex-col lg:flex-row w-[100%] min-h-[600px]  top-0 left-0 px-[20px] lg:px-[12rem] sm:px-[50px] md:px-[100px] pt-[40px] gap-3 md:gap-5">
            <div className="flex-1 flex flex-col justify-center items-start text-justify">
              <h1 className="text-[24px] sm:text-[36px] lg:text-[48px] font-Emilio text-[#650010] text-left mb-4 font-bold">
                Our Story
              </h1>
              <p className="text-[16px] sm:text-[18px] font-Montserrat text-[#000] lg:font-thin font-normal">
                Welcome to Sri Naveena Sweets and Bakery, where every bite is a
                testament to decades of dedication, perseverance, and an
                unwavering commitment to quality.
                <br /> Our journey began humbly in 1984 when Mr. Kanadam
                Srinivas Rao, a lineman in a sweets market, embarked on a path
                fueled by passion for the confectionery industry. Through years
                of hard work and relentless determination, we transformed
                challenges into opportunities, laying the foundation for what
                would become Sri Naveena Sweets.
              </p>
            </div>

            <div className="flex-1 flex justify-center items-center">
              <div className=" w-[100%] relative">
                <img
                  src={shop}
                  alt="libbanner"
                  className="z-10 lg:top-0 -top-2"
                />
                <div className="absolute lg:-top-10 -top-2 -right-16 w-full h-full bg-[#650010] -z-10"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:px-[12rem] p-[20px] flex flex-col gap-4">
          <p className="text-[16px] sm:text-[18px] font-Montserrat text-[#000] lg:font-thin font-normal text-justify">
            In 2009, the pivotal moment in our journey arrived when the
            unwavering support and invaluable assistance of Mr. Rao's wife
            became the cornerstone of our success story. Together, they formed
            an indomitable team, navigating obstacles with grace and seizing
            every opportunity that came their way. Their shared vision and
            tireless efforts propelled Sri Naveena Sweets into a thriving
            enterprise, marking a significant milestone in our legacy. <br />{' '}
            Today, the torch of excellence is carried forward by Mr. Kanadam
            Naveen and his wife Deepti, who stand as guardians of our tradition
            of unparalleled quality. With steadfast determination, they uphold
            the principles of craftsmanship and attention to detail that define
            Sri Naveena Sweets, ensuring that every sweet that leaves our
            kitchen is a masterpiece of flavor and quality.
          </p>

          <p className="text-[16px] sm:text-[18px] font-Montserrat my-6 lg:font-thin font-normal text-center">
            We invite you to join us and experience the difference at Sri
            Naveena Sweets and Bakery, where your trust meets our quality, and
            every sweet tells a story of passion and perseverance.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutBanner;
