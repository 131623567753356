import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { FetchSanity } from '../helpers/FetchSanity';
import FetchImageUrl from '../helpers/FetchImageUrl';

import ProductInfo from '../components/Product/ProductInfo';
import ViewAll from '../components/Home/ViewAll';
import ProductImage from '../components/Product/ProductImage';
import BreadCrumbs from '../components/Product/BreadCrumbs';
import Banner from '../components/Banner/Banner';

const Product = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  let { productId } = useParams();
  const { getImageUrl } = FetchImageUrl();
  const { fetchItems, Items } = FetchSanity();

  useEffect(() => {
    fetchItems(productId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const relatedProducts = [];

  const productInfo =
    Items && Items.filter((item) => item.item_id === productId)[0];

  if (Items && Items.length > 5) {
    const randomIndexes = [];

    while (randomIndexes.length < 5) {
      const randomIndex = Math.floor(Math.random() * Items.length);

      if (!randomIndexes.includes(randomIndex)) {
        randomIndexes.push(randomIndex);
      }
    }

    const randomProducts = randomIndexes.map((index) => Items[index]);

    relatedProducts.push(randomProducts);
  }

  // console.log(productInfo);

  const category =
    productInfo &&
    productInfo.items_category.split('_').join(' ').replace('/', '');

  return productInfo ? (
    <>
      <Banner title={productInfo?.name} />
      <div className="p-[1em] w-full flex lg:items-start items-center flex-col lg:p-[4em]">
        <BreadCrumbs productInfo={productInfo} category={category} />
        <div className="lg:flex mt-4 lg:justify-around lg:items-start lg:px-8 lg:gap-4 w-full">
          <ProductImage productInfo={productInfo} />
          <ProductInfo productInfo={productInfo} />
        </div>

        {relatedProducts.length > 0 && (
          <div className="related-products-container p-5">
            <h2 className="text-2xl font-bold mb-4">Related Products</h2>

            <div className="lg:flex items-center gap-4 grid grid-cols-2">
              {relatedProducts[0].map((data, i) => {
                const product = data.item_id;
                const category = data.items_category;
                return (
                  <Link
                    key={i}
                    to={`/${category}/${product}`}
                    className="bg-white shadow-[0_0_10px_rgba(0,0,0,0.1)] overflow-hidden h-[190px] md:w-[190px]  max-w-full rounded-lg"
                  >
                    <img
                      src={getImageUrl(data.images[0])}
                      alt={data.name}
                      className="w-full h-full border-b-[#ddd] border-b border-solid"
                    />
                  </Link>
                );
              })}

              <Link to={`/${productInfo.items_category}`}>
                <ViewAll />
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <></>
  );
};

export default Product;
