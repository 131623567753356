import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PostMongo from '../../helpers/PostMongo';

const NewAddressForm = ({ onClose, editData, fetchAgain }) => {
  const { updateAddress } = PostMongo();

  // console.log(editData);
  const [formData, setFormData] = useState({
    aId: '',
    fullname: '',
    mobile: '',
    email: '',
    fullAddress: '',
    landmark: '',
    state: '',
    pincode: '',
  });

  useEffect(() => {
    if (editData) {
      setFormData(editData);
    } else {
      const currentDate = new Date();
      const date = currentDate.getDate();
      const month = currentDate.getMonth();
      const year = currentDate.getFullYear() % 100;
      const hours = currentDate.getHours().toString().padStart(2, '0');
      const minutes = currentDate.getMinutes().toString().padStart(2, '0');
      const seconds = currentDate.getSeconds().toString().padStart(2, '0');

      const id = `SNSUI${date}${month + 1}${year}${hours}${minutes}${seconds}`;
      setFormData({ ...formData, aId: id });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log(formData);
    await updateAddress(formData).then(() => fetchAgain());
    onClose();
  };

  const handleClose = () => onClose();

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Full Name"
        variant="outlined"
        fullWidth
        margin="normal"
        name="fullname"
        value={formData.fullname}
        onChange={handleChange}
        required
      />
      <div className="lg:flex lg:gap-4">
        <TextField
          label="Mobile"
          variant="outlined"
          fullWidth
          margin="normal"
          name="mobile"
          value={formData.mobile}
          onChange={handleChange}
          required
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <TextField
        label="Full Address"
        variant="outlined"
        fullWidth
        margin="normal"
        name="fullAddress"
        value={formData.fullAddress}
        onChange={handleChange}
        required
      />
      <TextField
        label="Landmark"
        variant="outlined"
        fullWidth
        margin="normal"
        name="landmark"
        value={formData.landmark}
        onChange={handleChange}
      />
      <div className="lg:flex lg:gap-4">
        <TextField
          label="State"
          variant="outlined"
          fullWidth
          margin="normal"
          name="state"
          value={formData.state}
          onChange={handleChange}
          required
        />
        <TextField
          label="Pincode"
          variant="outlined"
          fullWidth
          margin="normal"
          name="pincode"
          value={formData.pincode}
          onChange={handleChange}
          required
        />
      </div>
      <div className="flex gap-2">
        <p
          className="border border-red-600 px-5 font-sans py-2 cursor-pointer rounded-sm"
          onClick={handleClose}
        >
          CLOSE
        </p>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </div>
    </form>
  );
};

export default NewAddressForm;
