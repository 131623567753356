import React from 'react';
import { Link } from 'react-router-dom';

import FetchImageUrl from '../../helpers/FetchImageUrl';

const CategoryGrid = ({ data }) => {
  const { getImageUrl } = FetchImageUrl();
  const sellingprice = data.sale_price;
  const costprice = data.cost_price;
  const diff = costprice - sellingprice;

  const off = (diff / costprice) * 100;
  return (
    <Link
      to={`${data.item_id}`}
      className="flex relative flex-col justify-between lg:min-h-[20em] h-fit bg-white w-full shadow-[0px_1px_13px_rgba(0,0,0,0.1)] cursor-pointer transition-all duration-[120ms] lg:p-3"
    >
      <div className="grid items-center">
        <img
          className="w-full h-full"
          src={getImageUrl(data.images[0])}
          alt=""
        />
      </div>
      <div className="flex flex-col h-full justify-end p-2">
        <div className="font-googleCakes self-start text-[1.2em] tracking-[1px] font-[200] text-[color:var(--secondary-bg)] ">
          {data.name}
        </div>
        <div className="text-[0.9em] text-black font-[600]">
          ₹ {data.sale_price}.00/-
        </div>

        {data.cost_price > data.sale_price && (
          <div className=" flex items-center right-4 justify-center text-[16px] bottom-0 lg:right-4 py-2.5 absolute px-1 rounded-[50%] font-[700] text-green-500">
            {Math.ceil(off)}% off
          </div>
        )}
      </div>
    </Link>
  );
};

export default CategoryGrid;
