import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { SnackbarProvider } from 'notistack';
import { OrdersContextProvider } from './context/orderContext';

const firebaseConfig = {
  apiKey: 'AIzaSyA6R8XZofDX7NAafaO7urH1BoMG-G80_VU',
  authDomain: 'sri-naveena-sweets-8564c.firebaseapp.com',
  projectId: 'sri-naveena-sweets-8564c',
  storageBucket: 'sri-naveena-sweets-8564c.appspot.com',
  messagingSenderId: '468763369452',
  appId: '1:468763369452:web:3f419bb3938717a0d36ac9',
  measurementId: 'G-B3FBTRVWLK',
};

firebase.initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <OrdersContextProvider>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </OrdersContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
