import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import CheckoutPaper from '../components/Checkout/Paper';
import Banner from '../components/Banner/Banner';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const Checkout = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const location = useLocation();

  const [products, setProducts] = useState();
  const { data = null } = location.state || {};

  useEffect(() => {
    if (data) {
      const extractedData = data.map(({ item_id, price, quantity }) => ({
        itemId: item_id,
        price: price,
        quantity: quantity,
      }));

      setProducts(extractedData);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();

  return (
    <React.Fragment>
      {/* <Banner title={'Checkout'} /> */}
      <CssBaseline />
      <CheckoutPaper products={products} classes={classes} data={data} />
    </React.Fragment>
  );
};

export default Checkout;
