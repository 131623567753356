import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';

import { useLogout } from '../../context/useLogout';
import { Link } from 'react-router-dom';

import { FiShoppingCart } from 'react-icons/fi';
import { FaRegUser } from 'react-icons/fa';
import { GrFavorite } from 'react-icons/gr';

export default function ProfileIcon({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout } = useLogout();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleClose();

    await logout();
  };

  // console.log(data);
  const menu = [
    { name: 'Profile', to: 'profile', icon: <FaRegUser fontSize="1.2rem" /> },
    {
      name: 'Favorites',
      to: 'favorites',
      icon: <GrFavorite fontSize="1.2rem" />,
    },
    { name: 'Cart', to: 'cart', icon: <FiShoppingCart fontSize="1.2rem" /> },
  ];

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', textAlign: 'center' }}>
        <Tooltip title="Profile">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ m: 0, padding: 0 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 38, height: 38 }}>
              <img
                className="w-full h-full"
                src={data && data.displayPic}
                alt=""
              />
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {window.innerWidth > 768 &&
          menu.map((item, i) => {
            return (
              <Link to={`/${item.to}`} className="w-full" key={i}>
                <MenuItem
                  onClick={handleClose}
                  sx={{
                    paddingRight: 6,
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  {item.name}
                </MenuItem>
              </Link>
            );
          })}

        <Divider />

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
