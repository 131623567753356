import React from 'react';

import { Link } from 'react-router-dom';

import FetchImageUrl from '../../helpers/FetchImageUrl';

import ViewAll from '../../components/Home/ViewAll';

const Cakes = ({ Cakes }) => {
  const { getImageUrl } = FetchImageUrl();
  const backgroundImagePath = process.env.PUBLIC_URL + '/assets/cakes.jpg';

  const phoneNumber = '+919618335976';
  return (
    <div className="relative flex flex-col items-center">
      <h3
        className="cakesHead font-googleCursive text-[5rem] text-center my-5 bg-cover bg-center w-full"
        style={{ backgroundImage: `url(${backgroundImagePath})` }}
      >
        Cakes
      </h3>

      <h4 className="my-3 font-googleNunito text-center w-full pr-4">
        For customized cakes contact us on{' '}
        <span
          onClick={() => (window.location.href = `tel:${phoneNumber}`)}
          className="cursor-pointer text-blue-600"
        >
          +919618335976
        </span>
      </h4>

      <div
        className={`flex justify-center gap-6 lg:px-10 flex-col lg:flex-row`}
      >
        {Cakes &&
          Cakes.slice(0, 4).map((data, i) => {
            const sellingprice = data.sale_price;
            const costprice = data.cost_price;
            const diff = costprice - sellingprice;

            const off = (diff / costprice) * 100;
            return (
              <Link
                to={`${data.items_category}/${data.item_id}`}
                key={i}
                className={`flex relative flex-col bg-white ${
                  window.innerWidth > 768 ? 'w-[14em]' : 'w-[20em]'
                } shadow-[0px_1px_13px_rgba(0,0,0,0.1)] cursor-pointer transition-all duration-[120ms] p-3`}
              >
                <div className="w-full grid place-items-center p-[0.5em]">
                  <img
                    className={`w-full ${
                      window.innerWidth > 768 ? ' h-[14em]' : 'h-[18em]'
                    }`}
                    src={getImageUrl(data.images[0])}
                    alt=""
                  />
                </div>
                <div className="font-googleCakes self-start text-[1.2em] tracking-[1px] font-[200] text-[color:var(--secondary-bg)] ">
                  {data.name}
                </div>
                <div className="text-[0.9em] text-black font-[600]">
                  ₹ {data.sale_price}.00/-
                </div>

                {data.cost_price > data.sale_price && (
                  <div className=" flex items-center justify-center text-[16px] bottom-0 right-5 py-2.5 absolute px-1 rounded-[50%] font-[700] text-green-500">
                    {Math.floor(off)}% off
                  </div>
                )}
              </Link>
            );
          })}
        <Link to="/cakes" className="flex items-center justify-end">
          <ViewAll />
        </Link>
      </div>
    </div>
  );
};

export default Cakes;
