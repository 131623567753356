import React, { useEffect } from 'react';

import { CardContent, Typography } from '@mui/material';

import FetchImageUrl from '../../helpers/FetchImageUrl';
import FetchMongo from '../../helpers/FetchMongo';

const Review = ({ aId, items, data, sendData }) => {
  const { fetchAddress, address } = FetchMongo();
  const { getImageUrl } = FetchImageUrl();

  useEffect(() => {
    fetchAddress({ id: aId });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let totalPrice = 0;

  if (data) {
    data.forEach((item) => {
      totalPrice += item.quantity * item.price;
    });
  }

  useEffect(() => {
    sendData({
      items: items,
      address: address && address,
      totalPrice: totalPrice + totalPrice * 0.04, // add shipping cost and tax here
    });
  }, [address]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {data && address ? (
        <>
          <table className="w-full">
            <thead>
              <tr className=" text-center ">
                <th className="font-semibold">Product</th>
                <th className="font-semibold">Price</th>
                <th className="font-semibold">Quantity</th>
                <th className="font-semibold">Total</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className="lg:py-4">
                      <div className="lg:flex gap-4 items-center">
                        <div>
                          <img
                            className="w-24"
                            src={getImageUrl(item.images[0])}
                            alt=""
                          />
                        </div>
                        <div>
                          <p className="font-semibold">{item.name}</p>
                          <p className=" text-gray-700 text-xs">
                            {item.description}
                          </p>
                          <p className=" text-gray-700 text-xs">
                            {item.weight}{' '}
                            {item.category === 'biscuits' ? 'pieces' : 'kg'}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="text-nowrap">₹ {item.price}.00</td>
                    <td className="text-center">{item.quantity}</td>
                    <td className="text-center">
                      ₹ {item.quantity * item.price}.00
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <CardContent sx={{ textAlign: 'right' }}>
            <Typography variant="h5" component="div">
              {address.fullname}
            </Typography>
            <Typography
              variant="b"
              color="text.secondary"
              sx={{ fontWeight: 'bold' }}
            >
              {address.mobile}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {address.email}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {address.fullAddress}, near {address.landmark}
            </Typography>

            <Typography variant="body2" color="text.secondary">
              {address.state}, {address.pincode}
            </Typography>
          </CardContent>
        </>
      ) : (
        <div className="flex flex-row gap-2">
          <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce"></div>
          <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:-.3s]"></div>
          <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:-.5s]"></div>
        </div>
      )}
    </div>
  );
};

export default Review;
