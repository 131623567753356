import * as React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa';

import { SiMongodb, SiExpress, SiReact, SiNodedotjs } from 'react-icons/si';
import Search from '../components/Navbar/Search';

const Footer = ({ Items }) => {
  const logo_path = process.env.PUBLIC_URL + '/assets/Logo.png';

  const allNavItems = [
    { title: 'Sweets', linkTo: 'sweets' },
    { title: 'Cakes', linkTo: 'cakes' },
    { title: 'Hot Items', linkTo: 'hot_items' },
    { title: 'Biscuits', linkTo: 'biscuits' },
  ];

  return (
    <Box
      sx={{
        bgcolor: '#ebe3c4',
        color: 'text.secondary',
        py: 6,
        borderTop: '1px solid',
        borderColor: 'divider',
        paddingBottom: window.innerWidth < 768 && '4em',
      }}
    >
      <Container sx={{ width: '100%' }}>
        <div className="flex lg:flex-row relative flex-col gap-4 w-full">
          <Link to="/" className="flex w-[10em] lg:absolute">
            <img src={logo_path} alt="" className="w-full h-full" />
          </Link>

          <div className="w-full flex lg:flex-col justify-between lg:justify-start px-6 gap-4">
            <div className="flex flex-col lg:flex-row justify-center lg:gap-12">
              {allNavItems.map((item, i) => {
                return (
                  <Link
                    key={i}
                    to={`/${item.linkTo}`}
                    sx={{
                      textDecoration: 'none',
                      color: 'rgb(75 85 99)',
                    }}
                    className="no-underline text-black w-fit hover:underline"
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>

            <div className="flex flex-col lg:flex-row justify-center lg:gap-12">
              <Link
                sx={{
                  textDecoration: 'none',
                  color: 'rgb(75 85 99)',
                }}
                to="/terms-and-conditions"
                className="no-underline text-black w-fit hover:underline"
              >
                Terms and Conditions
              </Link>

              <Link
                sx={{
                  textDecoration: 'none',
                  color: 'rgb(75 85 99)',
                }}
                to="/privacy-policy"
                className="no-underline text-black w-fit hover:underline"
              >
                Privacy Policy
              </Link>

              <Link
                sx={{
                  textDecoration: 'none',
                  color: 'rgb(75 85 99)',
                }}
                to="/return-cancellations"
                className="no-underline text-black w-fit hover:underline"
              >
                Returns/Cancellations
              </Link>

              <Link
                sx={{
                  textDecoration: 'none',
                  color: 'rgb(75 85 99)',
                }}
                to="/shipping-policy"
                className="no-underline text-black w-fit hover:underline"
              >
                Shipping Policy
              </Link>
            </div>
          </div>
        </div>
        <div className="max-w-[450px]  mx-auto flex items-center justify-center my-[25px]">
          <Search Items={Items} />
        </div>
        <div className="w-full flex flex-col items-center justify-center gap-3 my-3">
          <Typography
            variant="p"
            color="text.secondary"
            sx={{ display: 'flex', gap: '4px' }}
          >
            Follow us on
            <a
              aria-label="Instagram"
              className="text-2xl text-[#666666]"
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.instagram.com/srinaveenasweets?utm_source=qr&igsh=MXUyNTRlNGZpYm1yMA=="
            >
              <FaInstagram />
            </a>
          </Typography>

          <Typography variant="body2" color="text.secondary" align="center">
            Sri Naveena Sweets and Bakery
          </Typography>

          <hr className="w-full" />

          <Typography
            variant="p"
            className="flex justify-center gap-2 items-center"
          >
            made with
            <span className="flex gap-1">
              <SiMongodb />
              <SiExpress />
              <SiReact />
              <SiNodedotjs />
            </span>
            <a
              href="https://vamshidharonline.com/"
              target="_blank"
              rel="noreferrer"
              className="hover:text-[#0982d9]"
            >
              vamshidhar dawoor
            </a>
          </Typography>
        </div>
      </Container>
    </Box>
  );
};

export default Footer;
