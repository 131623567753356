import React from 'react'
import shop from "../../assets/shop.jpg";

const AboutAddress = () => {
    return (
        <>
            <div className=' w-[100%] mx-auto max-h-[7500px] py-[40px] '>
                <h1 className='text-[24px] font-bold sm:text-[36px]  lg:text-[46px] font-Emilio text-[#650010] py-5 lg:font-thin text-center'>You can find us at!!!</h1>
                <div className='lg:flex flex-col items-center justify-center gap-3 mx-10 hidden '>
                    <div className='min-h-[400px] w-[100%] relative py-[40px] flex '>
                        <div className='basis-1/3'>
                            <div className='max-w-[550px] w-[100%] min-h-[270px] ml-[50px] xl:ml-[100px] relative mt-[60px] object-cover'>
                                <img src={shop} alt='advantage' fill className='min-h-[270px]' />
                                <div className='absolute w-full h-full top-10 left-10 border-2 border-[#650010] -z-10'>

                                </div>
                            </div>
                        </div>
                        <div className='basis-2/3 max-h-[400px] bg-[#FCE3E5] flex justify-center items-center text-center -z-20'>
                            <div className='basis-[20%] '></div>
                            <div className='basis-[80%] flex flex-col gap-[15px] justify-center items-center text-center px-[10px] md:px-[40px]'>
                                <h1 className='text-[26px] lg:text-[32px] font-Emilio text-[#650010] font-bold'>Sri Naveena Sweets</h1>
                                <p className='text-[#000] font-Montserrat text-[18px] font-medium text-center'>134-22, Pandu Ranga Nagar, Muthyala Reddy Nagar, Guntur, Andhra Pradesh 522007</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col items-center justify-center lg:hidden gap-5 px-[20px] sm:px-[50px] text-justify'>
                    <div className='max-w-[1020px] w-[100%] max-h-[900px] flex flex-col  relative '>
                        <div className='min-h-[150px]'></div>
                        <div className='absolute max-w-[250px] sm:max-w-[300px] md:max-w-[450px] w-[100%] min-h-[180px] sm:min-h-[250px] top-7 right-7 z-10 bg-cover bg-no-repeat bg-center' style={{ backgroundImage: `url(${shop})` }}></div>
                        <div className='absolute max-w-[250px] sm:max-w-[300px] md:max-w-[450px] w-[100%] min-h-[180px] sm:min-h-[250px] top-2 right-2 border-2 border-[#650010]'></div>
                        <div className='max-h-[750px] bg-[#FCE3E5] p-3 flex flex-col justify-center items-center text-center pt-[80px] sm:pt-[150px] '>
                            <p className='text-[20px] sm:text-[28px] font-Emilio font-bold text-[#650010] px-[20px]'>Sri Naveena Sweets </p>
                            <p className='text-[16px] sm:text-[18px] font-Montserrat font-medium px-[20px]'>134-22, Pandu Ranga Nagar, Muthyala Reddy Nagar, Guntur, Andhra Pradesh 522007</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutAddress
