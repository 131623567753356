import React, { useEffect } from 'react';

import Services from './Home/Services';

import Founders from '../components/AboutComponents/Founders';
import AboutAddress from '../components/AboutComponents/AboutAddress';
import Banner from '../components/Banner/Banner';
import AboutBanner from '../components/AboutComponents/AboutBanner';

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="w-full max-h-[100000px]">
      <Banner title={'About Us'} />
      <div className="">
        <AboutBanner />
        <Founders />
        <Services />
        <AboutAddress />
      </div>
    </div>
  );
};

export default About;
