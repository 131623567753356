import React, { useEffect } from 'react';

const ReturnsCancellations = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <div className="p-6 lg:px-[8rem] font-googleterms">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <h1 className="font-bold text-xl mb-8">Return/Cancellations</h1>

          <p>Last updated: April 5, 2024</p>

          <p>Thank you for shopping at Sri Naveena Sweets and Bakery</p>
        </div>

        <div>
          <p>
            As we prioritize your satisfaction with our products, we would like
            to outline our policy on refunds and returns. This Return and Refund
            Policy has been crafted with care to ensure transparency and
            clarity.
          </p>

          <p>
            The following terms apply to any products you have purchased from
            us.
          </p>
        </div>

        <div>
          <h4 className="font-semibold">Your Order Cancellation Rights</h4>

          <p>
            Please note that there are no cancellation rights available once an
            Order has been placed. All sales are final.
          </p>
        </div>

        <div>
          <h4 className="font-semibold">Conditions</h4>

          <p>
            Given the perishable nature of our goods, we can only entertain
            requests for returns or refunds in cases of damaged or defective
            items, provided they are reported within 20 minutes after delivery.
          </p>
        </div>

        <div>
          <h4 className="font-semibold">Contact Us</h4>

          <p>
            If you have any questions or concerns regarding our Returns and
            Refunds Policy, please feel free to reach out to us at
            naveenasweets@gmail.com
          </p>
        </div>

        <div>
          <p className="mt-6 text-center">
            Thank you for your understanding and continued support.
          </p>

          <p className="mt-2 text-center">Sri Naveena Sweets and Bakery</p>
        </div>
      </div>
    </div>
  );
};

export default ReturnsCancellations;
