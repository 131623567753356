import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { FaMinus, FaPlus } from 'react-icons/fa6';

import PostMongo from '../../helpers/PostMongo';

import { UseAuthContext } from '../../context/useAuthContext';

const ProductInfo = ({ productInfo }) => {
  const { user } = UseAuthContext();
  const navigate = useNavigate();
  const { updateCartData, addFavorite } = PostMongo();
  const { enqueueSnackbar } = useSnackbar();

  const sellingprice = productInfo && productInfo.sale_price;
  const costprice = productInfo && productInfo.cost_price;
  const diff = costprice - sellingprice;

  const off = (diff / costprice) * 100;

  const [quantity, setQuantity] = useState(1);

  const addToCart = async () => {
    const productId = productInfo.item_id;
    if (user) {
      await updateCartData({ productId, quantity });
      enqueueSnackbar('Added to cart!', {
        variant: 'success',
        autoHideDuration: 2000,
      });
      navigate('/cart');
    } else {
      enqueueSnackbar('Please signin!', {
        variant: 'warning',
        autoHideDuration: 2000,
      });
    }
  };

  return (
    <div className="lg:px-6 py-4 flex flex-col lg:w-[50%]">
      <span className="mb-4 inline-block text-xs font-bold uppercase tracking-widest text-primary lg:mb-6">
        {productInfo.sale_price !== 0 ? <>IN STOCK</> : <p>OUT OF STOCK</p>}
      </span>

      <h1 className="mb-4 text-3xl font-bold text-tertiary md:text-4xl lg:mb-10 lg:text-5xl">
        {productInfo.name}
      </h1>

      <p className="mb-6 max-w-md text-tertiary-light font-[600] text-gray-600">
        {productInfo.decription}
      </p>

      <div className="mb-5 flex items-start justify-start md:gap-8 lg:mb-8 flex-col lg:items-start lg:gap-2">
        <div className="flex items-end gap-4">
          <span className="text-3xl font-bold">
            ₹ {productInfo.sale_price}.00
          </span>
          {off !== 0 && (
            <>
              <span className="font-bold text-tertiary-dark">
                <s>₹ {productInfo.cost_price}.00</s>
              </span>
              <span className="rounded-md text-green-600 bg-secondary px-2 font-bold text-primary">
                {Math.floor(off)}% OFF
              </span>
            </>
          )}
        </div>
        {productInfo.weight && (
          <span className="text-[1.2rem] font-normal ">
            {productInfo.weight}{' '}
            {productInfo.items_category === 'biscuits'
              ? 'piece(s)'
              : productInfo.items_category === 'hot_items'
              ? 'piece(s)'
              : 'kg'}
          </span>
        )}
      </div>

      <div className="mb-4">
        <p className="text-tertiary-light text-1xl font-[600] mb-2 text-gray-600">
          Quantity
        </p>{' '}
        <div className="flex items-center max-w-[10rem] mb-3">
          <FaMinus
            className="bg-gray-100 text-[4rem] hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 cursor-pointer h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
            onClick={() => quantity !== 1 && setQuantity(quantity - 1)}
          />
          <input
            type="text"
            className=" font-medium text-center flex items-center text-gray-900 text-sm w-full "
            value={quantity}
            onChange={() => setQuantity(quantity)}
          />

          <FaPlus
            className="text-[4rem] bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 cursor-pointer h-11 focus:ring-gray-100 focus:ring-2 focus:outline-none"
            onClick={() => quantity !== 9 && setQuantity(quantity + 1)}
          />
        </div>
      </div>

      <div className="mb-4 flex flex-col lg:flex-row items-center gap-2">
        <button
          className="bg-black w-full text-white py-2 px-4 rounded-md hover:bg-primary-dark transition"
          onClick={addToCart}
        >
          Add to Cart
        </button>
        <button
          className="bg-[color:var(--primary-bg)] w-full text-white py-2 px-4 rounded-md hover:bg-tertiary-dark transition"
          onClick={() =>
            user ? addFavorite(productInfo.item_id) : alert('Please Signin')
          }
        >
          Add to Favorites
        </button>
      </div>
    </div>
  );
};

export default ProductInfo;
