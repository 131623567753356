import React, { useState } from 'react';
import { Button, Paper, Step, StepLabel, Stepper } from '@material-ui/core';

import Address from './Address';
import Review from './Review';
import ConfirmOrder from './ConfirmOrder';

import OrderConfirm from '../../helpers/OrderConfirm';

const steps = ['Shipping address', 'Review your order'];

const CheckoutPaper = ({ products, classes, data }) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const [orderDetails, setOrderDetails] = useState();
  const [addressId, setAddressId] = useState();

  const { confirm } = OrderConfirm();

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Address setId={setAddressId} />;
      case 1:
        return (
          <Review
            aId={addressId}
            items={products}
            data={data}
            sendData={(data) => setOrderDetails(data)}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        {/* <Typography component="h1" variant="h4" align="center">
          Checkout
        </Typography> */}
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
            <ConfirmOrder
              mobile={orderDetails.address.mobile}
              placeOrder={() => confirm({ orderDetails })}
            />
          ) : (
            <React.Fragment>
              {getStepContent(activeStep, setAddressId)}
              <div className={classes.buttons}>
                {activeStep !== 0 && (
                  <Button
                    onClick={() => handleBack()}
                    className={classes.button}
                  >
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleNext()}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                </Button>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      </Paper>
    </main>
  );
};

export default CheckoutPaper;
