import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';
import { Breadcrumbs } from '@mui/material';

import { FetchSanity } from '../helpers/FetchSanity';

import { GridLoader } from '../components/Loader/Loader';
import CategoryGrid from '../components/Category/CategoryGrid';
import Banner from '../components/Banner/Banner';

const Category = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const { fetchItems, Items } = FetchSanity();
  const category = window.location.pathname;

  const categoryName = category.split('/');
  const catName = categoryName[categoryName.length - 1];
  const modifiedPath = catName.split('_').join(' ').replace('/', '');

  const [catItems, setCatItems] = useState();

  useEffect(() => {
    fetchItems()
      .then(() => Items.filter((items) => items.items_category === catName))
      .then((data) => setCatItems(data));
  }, [category, catItems]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Banner title={modifiedPath} />
      <div
        className={`${
          window.innerWidth < 768
            ? 'p-[1em] flex items-start flex-col'
            : 'p-[4em]'
        }`}
      >
        <Breadcrumbs
          sx={{
            borderRadius: '4px',
            maxWidth: '400px',
            minWidth: '320px',
            width: '100%',
            backgroundColor: 'rgb(255,255,255)',
            display: 'flex',
            lineHeight: 'normal',
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: '5px',
          }}
          separator="/"
          name="breadcrumb"
          separatorcolor="#101113"
        >
          <Link
            sx={{
              textDecoration: 'none',
              font: '600 15px sans-serif',
              color: 'rgb(59, 67, 76)',
              letterSpacing: '0.5px',
            }}
            variant="subtitle"
            listType="ul"
            to="/"
            target="_self"
          >
            Home
          </Link>

          <Typography
            sx={{
              fontWeight: '600',
              color: 'rgb(59, 67, 76)',
              letterSpacing: '0.5px',
              opacity: '0.8',
              fontSize: '17px',
            }}
            variant="h4"
          >
            {modifiedPath}
          </Typography>
        </Breadcrumbs>

        <div
          className={`grid justify-center gap-6 grid-cols-2 sm:grid-cols-3 xl:grid-cols-4`}
        >
          {catItems ? (
            catItems.length !== 0 &&
            catItems.map((data, i) => {
              return <CategoryGrid key={i} data={data} />;
            })
          ) : (
            <div className="h-screen">
              <GridLoader />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Category;
