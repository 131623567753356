import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react';

import FetchImageUrl from '../../helpers/FetchImageUrl';

import { Link } from 'react-router-dom';

import { FaSearch } from 'react-icons/fa';

const Search = ({ Items }) => {
  const { getImageUrl } = FetchImageUrl();
  return (
    <div className="flex items-center justify-end">
      <div className="relative w-full ">
        <Autocomplete
          id="grouped-demo"
          options={Items.sort(
            (a, b) => -b.items_category.localeCompare(a.items_category)
          )}
          groupBy={(option) => option.items_category}
          getOptionLabel={(option) => option.name}
          sx={{ width: 350 }}
          renderOption={(props, option) => (
            <Link
              to={`${option.items_category}/${option.item_id}`}
              key={option.item_id}
            >
              <Box
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="30"
                  src={getImageUrl(option.images[0])}
                  alt=""
                />
                {option.name}
              </Box>
            </Link>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              id="outlined-search"
              label="Search"
              variant="filled"
              type="search"
              className=" bg-white rounded-[8px] outline-none overflow-hidden"
            />
          )}
        />
        <div
          type="submit"
          className="absolute flex justify-center items-center gap-2 top-0 end-0 p-2 text-sm w-[4rem] rounded-r-[8px] font-medium h-full text-white bg-[color:var(--primary-bg)]"
        >
          <FaSearch />
        </div>
      </div>
    </div>
  );
};

export default Search;
